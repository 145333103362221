/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/mixins';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.ContactForm {
    textarea {
        width: 100%;
    }

    .Field {
        &:first-of-type {
            margin-top: 25px;
        }

        &-Wrapper {
            &:first-of-type {
                .Field {
                    margin-block-start: 0;
                }
            }
        }

        &-SubTitle {
            display: none;
        }
    }

    Button {
        width: 100%;
    }
}
