/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/mixins';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

:root {
    --footer-content-height: 220px;
    --footer-copyright-height: 50px;
    --footer-total-height: calc(env(safe-area-inset-bottom) + var(--footer-nav-height));
    --footer-max-width: 1070px;
    --footer-nav-height: 60px;
}

.Footer {
    @include mobile {
        margin-block-end: var(--footer-nav-height);
    }

    &-CopyrightContentWrapper {
        background-color: var(--primary-dark-color);
        padding: 0 10px;

        @include desktop {
            min-height: 36px;
        }
    }

    &-CopyrightContent {
        border-top: 1px solid var(--primary-divider-color);
        display: flex;
        padding: 10px 80px;
        justify-content: center;
        align-items: center;

        @include mobile {
            padding-left: 30px;
            padding-right: 30px;
        }

        @include desktop {
            border-top: 1px solid var(--color-gray1);
        }
    }

    &-Copyright {
        font-size: 12px;
        text-align: center;
        color: var(--color-gray6);
        line-height: 18px;
        font-weight: $font-weight-regular;
        letter-spacing: $letter-spacing1;

        @include desktop {
            line-height: 15px;
        }

        a {
            color: inherit;
            font-weight: $font-weight-regular;
            font-size: 12px;

            &:hover {
                @include desktop {
                    color: var(--primary-dark-color);
                }
            }
        }
    }

    &-Content {
        min-height: var(--footer-content-height);
        background-color: var(--primary-dark-color);
        color: var(--color-white);
        padding: 0 10px;

        @include mobile {
            min-height: 711px;
        }

        a,
        button {
            color: var(--color-white);
            font-weight: $font-weight-medium;
        }

        .Button_likeLink {
            &:hover {
                @include desktop {
                    text-decoration: underline;
                    color: var(--color-white);
                }
            }
        }

        ul {
            li {
                list-style: none;
            }
        }
    }

    &-Wrap {
        display: flex;
        height: 100%;
        justify-content: space-between;
        max-width: var(--footer-max-width);
        margin: 0 auto;
        flex-direction: column;

        @include desktop {
            flex-direction: row;
            min-height: 377px;
        }

        &_first {
            text-align: center;
            margin-top: 40px;

            @include desktop {
                text-align: left;
            }

            img {
                width: 250px;
                margin: 0 auto 20px;
                display: block;

                @include mobile {
                    height: 37px;
                }

                @include desktop {
                    width: 205px;
                    height: 31px;
                    margin: 0 0 20px;
                }
            }

            span {
                display: inline;
                font-size: 12px;
                line-height: 18px;
                font-weight: $font-weight-regular;
                letter-spacing: $letter-spacing1;
                color: var(--color-gray6);

                @include desktop {
                    display: block;
                }
            }

            ul {
                margin-top: 20px;

                li {
                    display: flex;
                    margin-bottom: 0;

                    @include mobile {
                        justify-content: center;
                        align-items: center;
                    }

                    &::before {
                        display: none;
                        position: static;
                    }

                    .Button {
                        width: auto;

                        &_likeLink {
                            &:hover,
                            &:focus {
                                @include desktop {
                                    text-decoration: none;
                                }

                                span {
                                    @include desktop {
                                        text-decoration: underline;
                                    }
                                }
                            }
                        }

                        span {
                            font-size: 15px;
                            line-height: 19px;
                            font-weight: $font-weight-medium;
                        }

                        &::before {
                            padding-right: 0;
                            margin-right: 10px;
                        }
                    }

                    &:first-child {
                        margin-bottom: 15px;

                        .Button {
                            @include email-icon;
                        }
                    }

                    &:last-child {
                        .Button {
                            @include chat-icon;
                        }
                    }
                }
            }
        }

        &_toggle {
            border-top: 1px solid var(--primary-divider-color);
            margin-top: 40px;

            &.hidden-mobile {
                @include desktop {
                    border-top: 0;
                    display: flex;
                    margin-top: 15px;
                }
            }

            &_title {
                @include h5;

                color: var(--color-gray6);

                @include footer-show-icon;

                border-bottom: 1px solid var(--primary-divider-color);
                padding: 25px 0;

                &::after {
                    float: right;
                }

                @include desktop {
                    pointer-events: none;
                    user-select: none;

                    &::after {
                        display: none;
                        border-bottom: 0;
                    }
                }
            }

            @include desktop {
                details {
                    summary {
                        pointer-events: none;
                        user-select: none;
                    }

                    &:first-child {
                        padding-right: 55px;
                    }
                }
            }

            details[open] {
                summary {
                    @include footer-hide-icon;

                    padding-bottom: 15px;
                    border-bottom: 0;

                    @include desktop {
                        padding-bottom: 20px;
                    }
                }
            }

            ul {
                border-bottom: 1px solid var(--primary-divider-color);

                @include desktop {
                    border-bottom: 0;
                }

                li {
                    &::before {
                        display: none;
                    }

                    a {
                        width: auto;
                        line-height: 30px;
                        font-weight: $font-weight-regular;

                        @include desktop {
                            font-weight: $font-weight-medium;
                        }
                    }
                }
            }
        }

        &_last {
            h5 {
                color: var(--color-gray6);
                text-align: center;
                margin-top: 30px;

                @include desktop {
                    text-align: left;
                    margin-top: 40px;
                }
            }

            ul {
                padding: 0 33px;
                margin-top: 20px;

                @include desktop {
                    padding: 0;
                }

                li {
                    span {
                        display: block;
                        color: var(--color-white);
                        line-height: 20px;
                        font-size: 14px;
                        letter-spacing: $letter-spacing1;
                        padding-bottom: 5px;
                        padding-left: 65px;
                    }

                    &::before {
                        left: 0;
                    }
                }
            }

            &_section1 {
                max-width: 320px;
                margin: 0 auto;
                padding-bottom: 20px;

                @include desktop {
                    margin: 0;
                }

                &::before {
                    background: url(./images/payment.png) no-repeat;
                    background-size: contain;
                    width: 45px;
                    height: 45px;
                    content: "";
                    display: block;
                    top: 0;
                }
            }

            &_section2 {
                max-width: 320px;
                margin: 0 auto;

                &::before {
                    background: url(./images/payment_online.png) no-repeat;
                    background-size: contain;
                    width: 45px;
                    height: 45px;
                    content: "";
                    display: block;
                    top: 0;
                }
            }

            &_social {
                padding-bottom: 20px;

                h3 {
                    margin-top: 30px;
                }

                ul {
                    display: flex;
                    justify-content: center;

                    @include desktop {
                        justify-content: flex-start;
                    }

                    li {
                        &::before {
                            display: none;
                        }

                        &:last-child {
                            @include mobile {
                                margin-bottom: 0;
                            }

                            a {
                                margin-right: 0;
                            }
                        }

                        a {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            width: 30px;
                            height: 30px;
                            border-radius: 30px;
                            text-align: center;
                            margin-right: 15px;

                            &:hover {
                                @include desktop {
                                    background: var(--primary-base-color);
                                }
                            }
                        }
                    }
                }

                &_fb {
                    @include footer-facebook-icon;
                }

                &_twitter {
                    @include footer-twitter-icon;
                }

                &_insta {
                    @include footer-instagram-icon;
                }
            }
        }
    }

    &-Promo {
        @include mobile {
            padding-left: 10px;
            padding-right: 10px;
        }

        @include desktop {
            border-bottom: 1px solid var(--secondary-divider-color);
            max-width: var(--layout-max-width);
            margin: 0 auto;
            padding-top: 25px;
            min-height: 113px;
        }

        &_wrap {
            display: flex;
            flex-direction: column;
            padding: 0 20px;
            text-align: center;

            @include mobile {
                border-bottom: 1px solid var(--secondary-divider-color);
                padding-left: 10px;
                padding-right: 10px;
            }

            @include desktop {
                flex-direction: row;
                justify-content: space-between;
                padding: 0;
            }

            &_section {
                margin-bottom: 30px;

                @include desktop {
                    display: flex;
                    text-align: left;
                    width: 30%;
                    margin-bottom: 25px;
                }
            }

            img {
                width: 90px;
                height: 90px;

                @include desktop {
                    margin-right: 20px;
                    width: 62px;
                    height: 62px;
                }
            }

            h6 {
                margin-bottom: 10px;
                font-weight: $font-weight-semibold;
                min-height: 19px;

                @include desktop {
                    margin-bottom: 5px;
                }
            }

            span {
                font-size: 13px;
                line-height: 18px;
                font-weight: $font-weight-regular;
                letter-spacing: $letter-spacing;
                color: var(--color-gray1);
                min-height: 34px;
            }
        }
    }

    &-Subscription {
        padding: 30px 10px 0;
        max-width: var(--footer-max-width);
        margin: 0 auto 40px;

        @include desktop {
            margin: 10px auto 35px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0 24px;
            min-height: 77px;
        }

        h2 {
            color: var(--primary-light-color);
            margin-bottom: 5px;
            text-transform: none;

            @include mobile {
                padding-right: 30px;
                min-height: 72px;
            }

            @include desktop {
                margin: 28px 0 10px 0;
            }
        }
    }
}
