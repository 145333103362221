/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/mixins';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

.ExpandableContentShowMore {
    text-align: start;

    &-ShowAllButton {
        --button-height: fit-content;
        --button-hover-height: fit-content;
        --button-hover-padding: 0;

        @include paragraph-text;

        font-weight: $font-weight-medium;
        color: var(--color-gray9);
        letter-spacing: $letter-spacing;
        margin-top: -10px;
        margin-bottom: 25px;
        display: flex;
        flex-wrap: wrap;
        text-transform: capitalize;

        @include arrow-down-large-icon;

        &::after {
            margin-left: 10px;
        }

        &:hover {
            --button-hover-color: var(--color-gray9);

            text-decoration: none;

            span {
                @include desktop {
                    text-decoration: underline;
                }
            }
        }

        &:focus:not(:hover) {
            text-decoration: unset;
        }

        span {
            color: var(--color-gray9);
        }

        &_state {
            &_isOpen {
                @include arrow-up-large-icon;

                margin-top: -20px;
            }
        }
    }

    &-ExpandableChildren {
        transition: .5s;
        overflow: hidden;
        margin-top: -10px;
        margin-bottom: 10px;

        .Field-Wrapper {
            &:first-of-type {
                margin-top: 0;
            }
        }
    }
}
