/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */
@mixin button-disabled {
    --button-background: var(--color-gray3);
    --button-border: var(--color-gray3);
    --button-color: var(--color-gray7);
    --hollow-button-background: var(--color-gray3);
    --hollow-button-border: var(--color-gray3);
    --hollow-button-color: var(--color-gray7);
    --secondary-button-background: var(--color-gray3);
    --secondary-button-border: var(--color-gray3);
    --secondary-button-color: var(--color-gray7);
    --secondary-revert-button-background: var(--color-gray3);
    --secondary-revert-button-border: var(--color-gray3);
    --secondary-revert-button-color: var(--color-gray7);

    pointer-events: none;
}

@mixin button {
    text-decoration: var(--button-text-decoration);
    background-color: var(--button-background);
    border-radius: var(--button-border-radius);
    color: var(--button-color);
    font-family: var(--default-button-font-family);
    font-weight: var(--default-button-font-weight);
    font-size: var(--default-button-font-size);
    height: var(--button-height);
    line-height: var(--button-height);
    text-transform: var(--default-button-text-transform);
    padding: var(--button-padding);
    letter-spacing: var(--default-button-letter-spacing);
    border-width: var(--button-border-width);
    border-color: var(--button-border);
    border-style: solid;
    align-items: center;
    cursor: pointer;
    display: inline-flex;
    justify-content: center;
    transition-duration: .25s;
    transition-timing-function: ease-out;
    transition-property: background-color, color, border;
    will-change: background-color, color, border;

    @include mobile {
        user-select: none;
        width: 100%;
    }

    &:hover,
    &:focus {
        text-decoration: var(--button-hover-text-decoration);
    }

    &:not([disabled]) {
        &:hover {
            @include desktop {
                background-color: var(--button-hover-background);
                border-color: var(--button-hover-border);
                color: var(--button-hover-color);
            }
        }
    }

    &[disabled],
    &.disabled {
        @include button-disabled();

        &::before {
            color: var(--color-gray7);
        }
    }

    &_isHollow {
        background-color: var(--hollow-button-background);
        border-color: var(--hollow-button-border);
        color: var(--hollow-button-color);

        &:not([disabled]) {
            &:hover {
                @include desktop {
                    background-color: var(--hollow-button-hover-background);
                    border-color: var(--hollow-button-hover-border);
                    color: var(--hollow-button-hover-color);
                }
            }
        }
    }

    &_Secondary {
        background-color: var(--secondary-button-background);
        border-color: var(--secondary-button-border);
        color: var(--secondary-button-color);

        &:not([disabled]) {
            &:hover {
                @include desktop {
                    background-color: var(--secondary-button-hover-background);
                    border-color: var(--secondary-button-hover-border);
                    color: var(--secondary-button-hover-color);
                }
            }
        }
    }

    &_SecondaryRevert {
        background-color: var(--secondary-revert-button-background);
        border-color: var(--secondary-revert-button-border);
        color: var(--secondary-revert-button-color);

        &:not([disabled]) {
            &:hover {
                @include desktop {
                    background-color: var(--secondary-revert-button-hover-background);
                    border-color: var(--secondary-revert-button-hover-border);
                    color: var(--secondary-revert-button-hover-color);
                }
            }
        }
    }

    &_isWithoutBorder {
        border: 0;
    }

    &_isSmallButton {
        height: var(--button-small-height);
        line-height: var(--button-small-height);
        padding: var(--button-small-padding);
    }

    &_likeLink {
        --button-border: transparent;
        --button-background: transparent;
        --button-color: var(--secondary-dark-color);
        --button-hover-border: transparent;
        --button-hover-background: transparent;
        --button-hover-color: var(--primary-base-color);
        --button-padding: 0;
        --default-button-letter-spacing: 0.3px;
        --button-hover-padding: 0;
        --button-height: min-content;
        --button-hover-height: min-content;
        --default-button-font-weight: #{$font-weight-medium};

        border: none;
        text-transform: none;
        margin: 0;
        padding: 0;
        text-align: start;

        &:hover,
        &:focus {
            padding: 0;

            @include desktop {
                text-decoration: underline;
            }
        }
    }
}

@mixin button-link {
    color: var(--hollow-button-color);
    line-height: 22px;
    --default-button-letter-spacing: #{$letter-spacing8};
    --default-button-font-weight: #{$font-weight-semibold};

    &:hover {
        @include desktop {
            text-decoration: underline;
        }
    }
}
