/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

:root {
    // Colors
    --color-black: #{$black};
    --color-white: #{$white};
    --color-gray1: #{$grey1};
    --color-gray2: #{$grey2};
    --color-gray3: #{$grey3};
    --color-gray4: #{$grey4};
    --color-gray5: #{$grey5};
    --color-gray6: #{$grey6};
    --color-gray7: #{$grey7};
    --color-gray8: #{$grey8};
    --color-gray9: #{$grey9};
    --color-grey10: #{$grey10};
    --color-grey11: #{$grey11};
    --color-grey12: #{$grey12};
    --color-grey13: #{$grey13};
    --color-grey14: #{$grey14};
    --color-grey15: #{$grey15};
    --color-grey16: #{$grey16};
    --color-grey17: #{$grey17};
    --color-grey18: #{$grey18};
    --color-grey19: #{$grey19};
    --color-orange3: #{$orange3};
    --color-orange4: #{$orange4};
    --color-yellow1: #{$yellow1};
    --color-yellow2: #{$yellow2};
    --color-yellow3: #{$yellow3};
    --color-yellow4: #{$yellow4};
    --color-red2: #{$red2};
    --color-green1: #{$green1};
    --color-karaka: #0a0903;
    --color-green: #048714;
    --color-error: #f30000;
    --primary-divider-color: var(--color-gray4);
    --secondary-divider-color: var(--color-gray3);
    --secondary-error-color: var(--color-white);
    --primary-error-color: var(--color-red2);
    --secondary-success-color: var(--color-white);
    --primary-success-color: var(--color-green1);
    --secondary-info-color: var(--color-karaka);
    --primary-info-color: var(--color-yellow4);
    --body-content-color: var(--color-black);
    --body-background-color: var(--color-white);
    --account-background-color: var(--color-grey17);
    --body-paragraph-color: var(--color-gray1);
    --product-image-color: var(--color-gray5);

    --layout-max-width: #{$layout__max-width};

    // Placeholders
    --placeholder-gradient-color: #d9d9d9;
    --placeholder-image: linear-gradient(to right, #ebebeb 0, var(--placeholder-gradient-color) 15%, #ebebeb 30%);
    --placeholder-size: 1200px 100%;
    --placeholder-animation: placeholderAnimation 2s linear infinite;

    // Declare imported primary colors to change the theme's colors
    // "imported_..." variables are set up in Admin panel -> Stores -> Configuration -> Scandipwa -> Color Configuration
    // They have higher priority than "default_..." variables defined in code.
    --primary-base-color: var(--imported_primary_base_color, #{$default-primary-base-color});
    --primary-dark-color: var(--imported_primary_dark_color, #{$default-primary-dark-color});
    --primary-light-color: var(--imported_primary_light_color, #{$default-primary-light-color});
    --secondary-base-color: var(--imported_secondary_base_color, #{$default-secondary-base-color});
    --secondary-dark-color: var(--imported_secondary_dark_color, #{$default-secondary-dark-color});
    --secondary-light-color: var(--imported_secondary_light_color, #{$default-secondary-light-color});
}
