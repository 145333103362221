/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../../src/style/abstract/variables';
@import '../../../../../src/style/abstract/mixins';
@import '../../../../../src/style/abstract/media';
@import '../../../../../src/style/abstract/button';
@import '../../../../../src/style/abstract/loader';
@import '../../../../../src/style/abstract/icons';
@import '../../../../../src/style/abstract/parts';

/**
 * KemanaPWA - Progressive Web App for Magento
 * Copyright © Kemana, Inc. All rights reserved.
 *
 * @package kemana-blog
 * @author ashangunawardena
 */

 .BlogTags {
    border-top: 1px solid var(--color-grey16);
    margin-top: 25px;
    padding-top: 25px;

    &-List {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
    }

    &-ListItem {
        font-weight: $font-weight-regular;
        color: var(--primary-light-color);
        font-size: 10px;
        line-height: 12px;
        letter-spacing: $letter-spacing8;
        text-transform: uppercase;
        border: 1px solid var(--secondary-light-color);
        margin: 0 10px 10px 0;
        padding: 6px 12px;
        box-shadow: $box-shadow12;

        &:hover {
            @include desktop {
                border: 1px solid var(--primary-base-color);
                color: var(--primary-base-color);
            }
        }
    }

    &-NoResult {
        p {
            margin-bottom: 0;
        }
    }
}
