/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/mixins';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

.ImageZoomPopup {
    height: 100%;

    .ProductGallerywrap {
        max-width: 100%;
    }

    .ProductGallery-Additional {
        top: auto;

        .CarouselScroll-Content {
            display: flex;
            margin-left: 33px;
            margin-bottom: 10px;

            .CarouselScrollItem {
                margin-bottom: 0;
                margin-right: 10px;
            }
        }
    }

    &.Popup {
        height: 100%;
    }

    &-PopupContent.Popup-Content {
        max-height: 100vh;
        height: 100vh;
        max-width: 100vw;
        width: 100%;
        padding: 0;

        .Popup-CloseBtn {
            top: 10px;
            right: 6px;
        }
    }

    .ProductGallery {
        width: 100%;
        height: 100Vh;

        &-SliderWrapper {
            height: 100%;
        }
    }

    .Slider-Arrow {
        display: none;
    }
}
