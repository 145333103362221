/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../../src/style/abstract/variables';
@import '../../../../../src/style/abstract/mixins';
@import '../../../../../src/style/abstract/media';
@import '../../../../../src/style/abstract/button';
@import '../../../../../src/style/abstract/loader';
@import '../../../../../src/style/abstract/icons';
@import '../../../../../src/style/abstract/parts';

/**
 * KemanaPWA - Progressive Web App for Magento
 * Copyright © Kemana, Inc. All rights reserved.
 *
 * @package kemana-store-locator
 * @author ashangunawardena
 */

.GoogleMap {
    &-Container {
        height: var(--google-map-height);
    }

    &-MapContainer {
        height: 100%;

        .gm-style {
            .gm-style-iw {
                @include h6;

                font-weight: $font-weight-regular;
                line-height: 20px;
                max-width: 320px !important;
                padding: 20px;
                border-radius: 5px;
                box-shadow: $box-shadow7;

                .gm-ui-hover-effect {
                    top: 0 !important;
                    right: 0 !important;
                }

                .amasty-popup {
                    h4 {
                        + br {
                            display: none;
                        }
                    }
                }

                .amasty-popup {
                    h4 {
                        margin-bottom: 20px;
                    }

                    h2 {
                        margin-bottom: 0;
                    }

                    .StoreLocator {
                        &-StoreAddress {
                            position: relative;

                            &::before {
                                position: absolute;
                                left: 0;
                                top: 3px;
                            }
                        }

                        &-StorePhone {
                            padding-left: 24px;
                            padding-right: 5px;
                            position: relative;

                            a {
                                @include h6;

                                font-weight: $font-weight-regular;
                                letter-spacing: $letter-spacing9;
                                line-height: 19px;

                                &:hover {
                                    span {
                                        @include desktop {
                                            text-decoration: underline;
                                        }
                                    }
                                }
                            }

                            &::before {
                                font-size: 12px;
                                position: absolute;
                                left: 0;
                                top: 1px;
                                margin-right: 0;
                            }
                        }
                    }
                }

                .gm-ui-hover-effect {
                    span {
                        -webkit-mask-image: none !important;
                        background: none;
                        width: auto !important;
                        height: auto !important;
                        margin: 0 !important;

                        @include close-icon;

                        &::before {
                            font-size: 10px;
                            font-weight: $font-weight-bold;
                        }
                    }
                }
            }

            .gm-style-iw-t {
                &::after {
                    width: 10px;
                    height: 10px;
                }
            }
        }
    }

    &-LoadingContainer {
      height: var(--google-map-height);
      position: relative;
    }

    &-Marker {
        font-size: 12px !important;
        position: absolute !important;
        left: 36px;
    }
}
