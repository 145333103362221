/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

:root {
    --table-body-border: 1px solid var(--color-gray3);
}

table {
    border: 1px solid var(--color-gray3);

    thead th {
        background-color: var(--color-gray4);
    }

    tbody tr:not(:last-child) {
        border-block-end: var(--table-body-border);
    }

    tbody tr {
        @include desktop {
            &:hover {
                background: var(--color-gray4);
            }
        }
    }

    th {
        padding: 15px 10px;
        text-align: left;

        @include h6;

        @include desktop {
            padding: 13px 0 13px 20px;
        }
    }

    td {
        padding: 15px 10px;
        text-align: start;

        @include paragraph-text;

        @include mobile {
            color: var(--primary-light-color);
        }

        @include desktop {
            padding: 20px 0 20px 20px;
        }
    }
}

.Table-Wrapper {
    max-width: 100%;
    overflow-x: auto;
}
