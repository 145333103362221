/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/mixins';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

.NotificationList {
    position: fixed;
    z-index: 800;
    top: var(--header-height);
    right: 30px;
    max-width: 550px;
    list-style: none;

    @include desktop {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-end;
        top: calc(var(--header-height) + 10px);
    }

    @include mobile {
        overflow: hidden;
        max-width: 100vw;
        width: 100%;
        right: 0;
        z-index: 555;

        &::before {
            content: '';
            width: 100%;
            height: 1px;
            box-shadow: $box-shadow2;
            display: flex;
            position: absolute;
            z-index: 1;
        }
    }
}
