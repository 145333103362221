/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/mixins';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

.KeyValueTable {
    margin-bottom: 12px;
    min-width: 100%;
    font-size: 14px;
    text-align: left;

    @include mobile {
        margin-bottom: 20px;
    }

    tbody {
        tr {
            th {
                padding: 20px 15px;
                min-width: 120px;

                @include desktop {
                    padding: 20px;
                    min-width: 170px;
                }
            }
        }
    }

    &-HeadingContainer {
        background: var(--color-gray4);
        padding-left: 15px;
        padding-right: 15px;

        @include desktop {
            padding-left: 20px;
            padding-right: 20px;
        }

        @include print-only {
            width: 100%;
            display: flex;
        }

        .KeyValueTable {
            &-Heading {
                @include print-only {
                    width: auto;
                }
            }

            &-Row {
                @include print-only {
                    flex-wrap: wrap;
                }
            }
        }
    }

    &-Heading {
        @include h6;

        line-height: 20px;
        padding: 15px 15px 15px 0;
        width: 100%;

        @include desktop {
            padding-right: 20px;
        }

        &:first-of-type {
            padding-left: 0;
        }

        &:last-of-type {
            padding-right: 0;
        }

        span {
            + span {
                color: var(--color-gray2);
            }
        }
    }

    &-Content {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        padding-left: 15px;
        padding-right: 15px;
        border: 1px solid var(--secondary-divider-color);
        border-top: 0;
        margin-top: -1px;

        @include desktop {
            padding-left: 20px;
            padding-right: 20px;
        }

        .KeyValueTable-Row {
            &:hover {
                @include desktop {
                    background: var(--color-gray4);
                    margin: 0 -20px;
                    padding-left: 20px;
                    padding-right: 20px;
                    width: calc(100% + 40px);
                }
            }
        }
    }

    &-Row {
        width: 100%;
        display: flex;
        border-bottom: 1px solid var(--secondary-divider-color);

        @include print-only {
            flex-wrap: wrap;
        }

        &:last-of-type {
            border-color: transparent;

            .KeyValueTable-Column {
                @include print-only {
                    border-bottom: 0;
                    margin-bottom: 0;
                }
            }
        }

        .KeyValueTable-Column {
            @include print-only {
                border-bottom: 1px solid var(--secondary-divider-color);
                margin-bottom: 20px;
            }
        }
    }

    &-Column {
        @include paragraph-text;
        
        padding: 20px 15px 20px 0;
        min-width: 50%;
        word-break: break-word;

        @include mobile {
            padding-bottom: 15px;
        }

        @include desktop {
            padding: 20px 20px 20px 0;
        }

        &:nth-child(1) {
            color: var(--primary-light-color);

            strong {
                @include h6;
            }
        }
    }

    &-NoOrders {
        @include paragraph-text;
        
        padding: 20px 0;
    }

    &-Item {
        &-Name {
            border-block-start: 1px solid rgba(10, 10, 10, .5);
        }
    }

    &-Totals {
        &-Separated {
            border-block-start: 1px solid rgba(10, 10, 10, .5);
        }
    }

    &-Wrapper {
        @include mobile {
            overflow-x: auto;
        }
    }

    &-Footer {
        .MyAccountOrderTotals {
            &-BottomTotalContainer {
                @include mobile {
                    width: 100%;
                }
            }
        }
    }

    .RadioButtonIcon {
        display: none;
    }
}
