/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/mixins';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

.Pagination {
    display: flex;
    justify-content: center;
    margin: 0 auto;
    align-items: center;
    z-index: 2;
    width: min-content;

    @include desktop {
        order: 1;
    }

    @include mobile {
        padding-block-end: 0;
        display: none;
    }

    .ChevronIcon {
        cursor: pointer;
        display: none;
        height: 20px;
        width: 16px;

        &:hover,
        &:focus {
            fill: var(--primary-base-color);
        }
    }

    &-ListItem {
        margin-block-end: 0;
        padding-inline-start: 0;
        display: block;

        &::before {
            content: '';
        }

        &:first-child {
            .PaginationLink_isArrow {
                @include arrow-left-icon;

                margin-right: 20px;

                &::after {
                    font-size: 12px;
                    line-height: 20px;
                    margin-top: 1px;
                }

                &:hover {
                    &::after {
                        @include desktop {
                            color: var(--color-pagination-link-active-text);
                        }
                    }
                }
            }
        }

        &:last-child {
            margin-bottom: 0;

            .PaginationLink_isArrow {
                @include arrow-right-icon;

                margin-left: 20px;

                &::after {
                    font-size: 12px;
                    line-height: 20px;
                    margin-top: 1px;
                }

                &:hover {
                    &::after {
                        @include desktop {
                            color: var(--color-pagination-link-active-text);
                        }
                    }
                }
            }
        }
    }
}
