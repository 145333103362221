@mixin grey-page-bg {
    @include mobile {
        background: #fff;
    }

    &::before {
        z-index: -1;
        content: '';
        position: fixed;
        inset-inline-start: 0;
        inset-inline-end: 0;
        inset-block-start: 0;
        inset-block-end: 0;
        background: var(--secondary-base-color);

        @include desktop {
            content: none;
        }
    }
}

@mixin default-list {
    ul,
    ol {
        margin-inline-start: 20px;

        li {
            &::before {
                inset-inline-start: -20px;
            }
        }
    }
}
