/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../../src/style/abstract/variables';
@import '../../../../../src/style/abstract/mixins';
@import '../../../../../src/style/abstract/media';
@import '../../../../../src/style/abstract/button';
@import '../../../../../src/style/abstract/loader';
@import '../../../../../src/style/abstract/icons';
@import '../../../../../src/style/abstract/parts';

/**
 * KemanaPWA - Progressive Web App for Magento
 * Copyright © Kemana, Inc. All rights reserved.
 *
 * @package kemana-date-picker
 * @author ashangunawardena
 */

:root {
    --kemana-datepicker-base-font: #{$base-font-family};
    --kemana-datepicker-box-shadow: #{$box-shadow1};

    --kemana-datepicker-month-container-desktop-width: 340px;
    --kemana-datepicker-month-container-mobile-width: 300px;

    --kemana-datepicker-day-names-color: var(--color-gray5);
    --kemana-datepicker-disabled-date-color: var(--color-gray2);
    --kemana-datepicker-highlighted-color: var(--color-yellow3);

    --kemana-datepicker-date-width: 35px;
    --kemana-datepicker-date-height: 35px;

    @include mobile {
        --kemana-datepicker-date-width: 34px;
        --kemana-datepicker-date-height: 34px;
    }
}

.KemanaDatePicker {
    .react-datepicker {
        font-family: var(--kemana-datepicker-base-font);
        float: right;
        border-radius: 0;
        box-shadow: var(--kemana-datepicker-box-shadow);
        border: 0;

        &__month-container {
            @include desktop {
                width: var(--kemana-datepicker-month-container-desktop-width);
            }
        }

        &__triangle {
            display: none;
        }

        &__input-container {
            @include date-icon;

            &::before {
                position: absolute;
                right: 10px;
                font-size: 17px;
                top: 12px;
            }

            input {
                --input-background-color: transparent;
            }
        }

        &-popper {
            right: 0 !important;
            z-index: 99;
            transform: translate(0px, 0px) !important;
            top: 0px !important;

            &[data-placement^=top] {
                padding-bottom: 0;
            }

            &[data-placement^=bottom] {
                padding-top: 0;
            }
        }

        &__header {
            background: transparent;
            color: $white;
            padding: 0;
            border: 0;
            border-radius: 0;
        }

        &__day-names {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            background: var(--color-gray5);
            margin-bottom: 0;
            text-transform: uppercase;
            font-weight: $font-weight-semibold;
            letter-spacing: $letter-spacing1;
            font-size: 12px;
            padding: 0 25px;
        }

        &__month {
            background: var(--color-white);
            margin: 0;
            padding: 0 25px;
        }

        &__week {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            padding: 0 0 10px 0;
        }

        &__day-name,
        &__day,
        &__time-name {
            color: var(--primary-dark-color);
            width: 35px;
            height: 35px;
            line-height: 35px;
            margin: 0;
            font-weight: $font-weight-semibold;
            letter-spacing: $letter-spacing1;
            font-size: 12px;
        }

        &__day-name {
            color: var(--kemana-datepicker-disabled-date-color);
            text-transform: uppercase;
        }

        &__day {
            border-radius: 5px;
            border: 1px solid transparent;
            padding: 0 5px 0 5px;

            &:hover {
                border-radius: 5px;
                background: transparent;

                @include desktop {
                    border-color: var(--kemana-datepicker-highlighted-color);
                    background: var(--kemana-datepicker-highlighted-color);
                    color: var(--color-white);
                }
            }

            &--outside-month, 
            &--disabled {
                color: var(--kemana-datepicker-disabled-date-color);
            }

            &--selected {
                color: var(--color-white);
                background-color: var(--kemana-datepicker-highlighted-color);
                border-color: var(--kemana-datepicker-highlighted-color);

                &:hover {
                    background: var(--kemana-datepicker-highlighted-color) !important;
                }
            }

            &--keyboard-selected {
                color: var(--color-white);
                background-color: var(--kemana-datepicker-highlighted-color);
                border-color: var(--kemana-datepicker-highlighted-color);
            }
        }
    }

    .Field {
        &-DateContainer {
            .react-datepicker__input-container {
                z-index: 2;
    
                @include date-icon;
    
                &::before {
                    font-size: 16px;
                    line-height: 16px;
                    position: absolute;
                    right: 12px;
                    top: 11px;
                    z-index: 1;
                }
            }
    
            .react-datepicker__tab-loop {
                .react-datepicker-popper {
                    padding-top: 0;
                    transform: translate(0px, -40px) !important;
                    top: 40px !important;
    
                    @include desktop {
                        width: calc(100% + 20px);
                        margin-left: -20px;
                    }
    
                    .react-datepicker {
                        border-radius: 0;
                        border: 0;
                        box-shadow: var(--kemana-datepicker-box-shadow);
                        width: 100%;
    
                        .react-datepicker__triangle {
                            display: none;
                        }
    
                        .react-datepicker__month-container {
                            width: 100%;
    
                            @include mobile {
                                min-width: var(--kemana-datepicker-month-container-mobile-width);
                            }
    
                            .react-datepicker__header {
                                padding: 0;
                                background-color: transparent;
                                border: 0;
    
                                .react-datepicker__current-month {
                                    background: var(--kemana-datepicker-highlighted-color);
                                    padding: 23px 0;
                                    letter-spacing: 0.63px;
                                    font-size: 18px;
                                    font-weight: $font-weight-semibold;
                                    color: var(--color-white);
                                }
    
                                .react-datepicker__day-names {
                                    background: var(--kemana-datepicker-day-names-color);
                                    margin: 0;
                                    display: flex;
                                    flex-wrap: wrap;
                                    justify-content: center;
    
                                    .react-datepicker__day-name {
                                        color: var(--kemana-datepicker-disabled-date-color);
                                        font-size: 12px;
                                        font-weight: $font-weight-semibold;
                                        letter-spacing: $letter-spacing1;
                                        text-transform: uppercase;
                                        width: var(--kemana-datepicker-date-width);
                                        height: var(--kemana-datepicker-date-height);
                                        display: flex;
                                        align-items: center;
                                        justify-content: center;
                                        margin: 0 5px;
    
                                        @include mobile {
                                            margin-left: 3px;
                                            margin-right: 3px;
                                        }
                                    }
                                }
                            }
    
                            .react-datepicker__month {
                                margin: 0 0 10px;
    
                                .react-datepicker__week {
                                    display: flex;
                                    flex-wrap: wrap;
                                    justify-content: center;
    
                                    .react-datepicker__day {
                                        width: var(--kemana-datepicker-date-width);
                                        height: var(--kemana-datepicker-date-height);
                                        margin: 5px;
                                        display: flex;
                                        align-items: center;
                                        justify-content: center;
                                        color: var(--primary-light-color);
                                        font-size: 12px;
                                        font-weight: $font-weight-semibold;
                                        letter-spacing: $letter-spacing1;
                                        border-radius: 5px;
                                        transition: $transition2;
    
                                        @include mobile {
                                            margin-left: 3px;
                                            margin-right: 3px;
                                        }
    
                                        &:hover {
                                            color: var(--primary-light-color);
                                            background: transparent;
    
                                            @include desktop {
                                                color: var(--color-white);
                                                background-color: var(--kemana-datepicker-highlighted-color);
                                            }
                                        }
    
                                        &--keyboard-selected,
                                        &--selected {
                                            color: var(--color-white);
                                            background-color: var(--kemana-datepicker-highlighted-color);
                                        }
    
                                        &--outside-month {
                                            color: var(--color-gray3);
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    
        &Date {
            &-HeaderWrapperTop {
                width: 100%;
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                align-items: center;
                padding: 15px 20px;
                background: var(--kemana-datepicker-highlighted-color);
            }
    
            &-MonthandYear {
                letter-spacing: 0.63px;
                font-size: 18px;
                font-weight: $font-weight-semibold;
                color: var(--color-white);
            }
    
            &-BackwardButton {
                width: var(--kemana-datepicker-date-width);
                height: var(--kemana-datepicker-date-height);
                text-align: left;
                cursor: pointer;
    
                @include arrow-left-icon;
    
                &::after {
                    font-size: 13px;
                    font-weight: $font-weight-bold;
                    color: var(--color-white);
                }
    
                span {
                    display: none;
                }
            }
    
            &-ForwardButton {
                width: var(--kemana-datepicker-date-width);
                height: var(--kemana-datepicker-date-height);
                text-align: right;
                cursor: pointer;
    
                @include arrow-right-icon;
    
                &::after {
                    font-size: 13px;
                    font-weight: $font-weight-bold;
                    color: var(--color-white);
                }
    
                span {
                    display: none;
                }
            }
    
            &-HeaderWrapperBottom {
                width: 100%;
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                background: var(--kemana-datepicker-highlighted-color);
                padding-bottom: 15px;
                
                select {
                    font-size: 12px;
                    letter-spacing: $letter-spacing1;
                    font-weight: $font-weight-semibold;
                    border: 1px solid var(--input-border-color);
                }
            }
    
            &-Month {
                --input-padding: 10px 41px 11px 15px;
    
                padding: 0 10px;
    
                @include arrow-down-icon;
    
                &::after {
                    position: absolute;
                    right: 25px;
                    top: 17px;
                    font-size: 6px;
                    line-height: 6px;
                    font-weight: $font-weight-bold;
                }
            }
    
            &-Year {
                --input-padding: 10px 39px 11px 15px;
    
                padding: 0 10px;
    
                @include arrow-down-icon;
    
                &::after {
                    position: absolute;
                    right: 25px;
                    top: 17px;
                    font-size: 6px;
                    line-height: 6px;
                    font-weight: $font-weight-bold;
                }
            }
        }
    }
}
