/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../../src/style/abstract/variables';
@import '../../../../../src/style/abstract/mixins';
@import '../../../../../src/style/abstract/media';
@import '../../../../../src/style/abstract/button';
@import '../../../../../src/style/abstract/loader';
@import '../../../../../src/style/abstract/icons';
@import '../../../../../src/style/abstract/parts';

/**
 * KemanaPWA - Progressive Web App for Magento
 * Copyright © Kemana, Inc. All rights reserved.
 *
 * @package kemana-gift-wrapper
 * @author ashangunawardena
 */

.GiftWrapperPopup {
    &-Image{
        width: 1px;
    }

    &-Category {
        @include desktop {
            padding-right: 20px;
        }
    }

    &-CategoryFilter {
        max-width: 275px;
        width: 100%;

        .Field {
            margin-top: 0;

            &-Label {
                padding-bottom: 0;
            }

            &-SubTitle {
                display: none;
            }

            &-Wrapper {
                &:first-of-type {
                    margin-top: 0;
                }

                .Field {
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: space-between;
                    align-items: center;
                    width: 100%;
                }
            }

            .FieldSelect {
                max-width: 196px;
                width: 100%;
            
                &-Select {
                    font-size: 14px;
                }
            }
        }
    }

    &-List {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 10px;
        margin-top: 30px;

        @include desktop {
            grid-template-columns: repeat(4, 1fr);
            grid-gap: 20px;
        }

        .Image {
            height: 165px;
            margin-bottom: 10px;

            @include desktop {
                height: 155px;
            }

            &-Image {
                object-fit: cover;
            }
        }

        &FinalView {
            margin-top: 0;

            @include desktop {
                margin-bottom: 61px;
            }

            @include mobile {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                grid-gap: 0;
            }

            .GiftWrapperPopup-ListItem {
                &:nth-child(1),
                &:nth-child(2) {
                    @include mobile {
                        width: 49%;
                    }
                }

                &:nth-child(3) {
                    @include mobile {
                        border-top: 1px solid var(--primary-divider-color);
                        width: 100%;
                        margin-top: 20px;
                        padding-top: 20px;
                        display: flex;
                    }

                    .GiftWrapperPopup-GiftWrapTitle {
                        @include mobile {
                            margin-bottom: 0;
                            margin-right: 20px;
                        }
                    }
                }
            }
        }
    }

    &-ListItem {
        .Image-Image {
            border: 3px solid transparent;
        } 

        &_isActive {
            .GiftWrapperPopup-ItemTitle {
                transition: $transition2;
                color: var(--primary-base-color);
            }

            .Image-Image {
                transition: $transition2;
                border-color: var(--primary-base-color);
            } 
        }
    }

    &-CategoryTopSection {
        display: flex;
        flex-wrap: wrap;
        width: 100%;

        @include desktop {
            justify-content: space-between;
        }
    }

    &-Pagination {
        display: flex;
        flex-wrap: wrap;
        align-items: center;

        @include mobile {
            width: 100%;
            margin-top: 20px;
        }
    }

    &-PaginationText {
        margin-right: 10px;

        p {
            letter-spacing: $letter-spacing1;
            color: var(--secondary-light-color);
            font-size: 12px;
            margin-bottom: 0;
        }
    }

    &-PaginationButton {
        width: 35px;
        height: 35px;
        cursor: pointer;
        border: 1px solid var(--color-grey16);

        &:hover {
            @include desktop {
                border-color: var(--primary-base-color);
            }

            &::before {
                @include desktop {
                    color: var(--primary-base-color);
                }
            }
        }

        span {
            display: none;
        }
    }

    &-PaginationBack {
        margin-right: 10px;

        @include back-icon;

        &::before {
            font-size: 12px;
            line-height: 35px;
            margin-left: -2px
        }
    }

    &-PaginationRight {
        @include next-icon;

        &::before {
            font-size: 12px;
            line-height: 35px;
        }
    }

    &-GiftWrapTitle {
        margin-bottom: 20px;
    }

    &-ItemTitle {
        margin-bottom: 5px;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;
    }

    &-BottomButtons {
        margin-top: 30px;
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: flex-end;

        @include desktop {
            padding-right: 20px;
            padding-bottom: 20px;
        }
    }

    &-DismissButton {
        --button-color: var(--primary-base-color);
        --default-button-font-weight: #{$font-weight-semibold};
        --default-button-letter-spacing: #{$letter-spacing8};

        text-transform: uppercase;
        padding: 0 10px;

        &:hover,
        &:focus {
            padding: 0 10px; 
        }

        @include mobile {
            order: 3;
            margin-top: 20px;
        }
    }

    &-NextButton {
        @include desktop {
            margin-left: 10px;
        }

        @include mobile {
            order: 1;
        }
    }

    &-BackButton {
        @include desktop {
            margin-left: 10px;
        }

        @include mobile {
            margin-top: 10px;
            order: 2;
        }
    }

    &-DesktopHeader {
        margin-bottom: 20px;

        @include desktop {
            padding-left: 20px
        }
    }

    &-Container {
        @include desktop {
            @include custom-scroll;

            max-height: 372px;
            min-height: 396px;
            overflow: hidden;
            overflow-y: auto;
            padding: 0 20px;
        }
    }

    .Popup {
        &-Heading {
            @include desktop {
                @include h4;
    
                display: none;
                margin-bottom: 20px;
            }
        }

        &-CloseBtn {
            top: 0;

            @include desktop {
                right: 20px;
            }
        }

        &-Content {
            max-width: 760px;

            @include mobile {
                padding-top: 20px;
                padding-bottom: 20px;
            }

            @include desktop {
                min-width: 760px;
                max-height: 464px;
                padding-left: 0;
                padding-right: 0;
                overflow: inherit;
            }
        }
    }
}
