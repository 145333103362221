/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/mixins';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

// util class, is applied on <html> on fullscreen image

.ProductActions {
    &-SocialIconsContainer {
        display: flex;
    }

    &-SocialIconsTitle {
        font-size: 15px;
        line-height: 19px;
        font-weight: $font-weight-medium;
        letter-spacing: $letter-spacing;
        color: var(--color-gray9);
        display: flex;
        align-items: center;
        margin-left: 27px;
        cursor: pointer;

        @include share-icon;

        @include desktop {
            &:hover {
                > span {
                    text-decoration: underline;
                }
            }
        }
    }

    &-SocialIconsWrapper {
        position: absolute;
        grid-column-gap: 12px;
        grid-template-columns: repeat(4, 1fr);
        padding: 12px;
        box-shadow: $box-shadow7;
        z-index: 1;
        background: var(--color-white);
        display: none;
        top: 38px;
        left: -28px;

        &_isActive {
            display: grid;
        }

        &::before {
            content: "";
            position: absolute;
            width: 0;
            height: 0;
            right: 30px;
            bottom: -24px;
            box-sizing: border-box;
            border: 12px solid white;
            border-color: transparent transparent white white;
            transform-origin: 0 0;
            box-shadow: -3px 3px 3px 0 #f1efef;
            top: 3px;
            transform: translateX(100%) rotate(135deg);
        }

        .SocialShare {
            text-align: center;

            a {
                &:hover {
                    &::before {
                        color: var(--primary-base-color);
                    }
                }
            }

            &-ShareBtnWrapperFacebook {
                a {
                    @include share-facebook-icon;
                }
            }

            &-ShareBtnWrapperTwitter {
                a {
                    @include share-twitter-icon;
                }
            }

            &-ShareBtnWrapperEmail {
                a {
                    @include share-email-icon;
                }
            }

            &-ShareBtnWrapperWhatsapp {
                a {
                    @include share-wa-icon;
                }
            }
        }
    }
}

