/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../../src/style/abstract/variables';
@import '../../../../../src/style/abstract/mixins';
@import '../../../../../src/style/abstract/media';
@import '../../../../../src/style/abstract/button';
@import '../../../../../src/style/abstract/loader';
@import '../../../../../src/style/abstract/icons';
@import '../../../../../src/style/abstract/parts';

/**
 * KemanaPWA - Progressive Web App for Magento
 * Copyright © Kemana, Inc. All rights reserved.
 *
 * @package kemana-events
 * @author ashangunawardena
 */

.EventDetails {
    margin-top: 80px;

    @include desktop {
        max-width: calc(var(--content-wrapper-width) - 220px);
        margin: 0 auto;
    }

    &-AboutEventTitle {
        margin: 50px 0 30px 0;
    }

    &-EventContent {
        margin-bottom: 80px;

        h5 {
            margin-bottom: 20px;
        }

        h3 {
            margin-bottom: 30px;
        }

        .pagebuilder-column-group {
            margin-bottom: 15px;
        }

        [data-content-type="divider"] {
            margin-bottom: 30px;
            
            hr {
                border-bottom: 1px solid var(--color-grey16);
            }
        }
    }
}
