/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../../src/style/abstract/variables';
@import '../../../../../src/style/abstract/mixins';
@import '../../../../../src/style/abstract/media';
@import '../../../../../src/style/abstract/button';
@import '../../../../../src/style/abstract/loader';
@import '../../../../../src/style/abstract/icons';
@import '../../../../../src/style/abstract/parts';

/**
 * KemanaPWA - Progressive Web App for Magento
 * Copyright © Kemana, Inc. All rights reserved.
 *
 * @package kemana-events
 * @author ashangunawardena
 */

.EventList {
    margin-top: 80px;
    padding-bottom: 80px;

    @include desktop {
        margin-top: 0;
        padding-bottom: 0;
    }

    &-List {
        display: grid;
        grid-gap: 0 20px;
        grid-template-columns: repeat(1, 1fr);

        @include desktop {
            grid-template-columns: repeat(4, 1fr);
        }
    }

    &-ListItem {
        margin-bottom: 50px;

        @include desktop {
            margin-bottom: 80px;
        }

        &:last-of-type {
            @include mobile {
                margin-bottom: 20px;
            }
        }
    }

    &-SubTitle {
        margin-bottom: 30px;
    }

    &-ListItemTitle {
        margin: 20px 0;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;
    }

    &-ListItemDate {
        font-size: 15px;
        color: var(--color-gray2);
        line-height: 22px;
        font-weight: $font-weight-regular;
        letter-spacing: $letter-spacing2;
        margin-bottom: 20px;
    }

    &-ListItemDescription {
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;

        p {
            margin-bottom: 0;
        }
    }

    &-Pagination {
        .Pagination {
            @include desktop {
                margin-top: -40px;
                margin-bottom: 70px;
            }
        }
    }
}
