/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/mixins';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

.ForgotPassword {
    .ContentWrapper {
        padding-block-start: 1px;
        padding-block-end: var(--footer-nav-height);
        padding-inline: 10px;

        @include desktop {
            padding: 80px 20px 90px;
            margin-top: -12px;
        }
    }

    &-CreateAccountButton,
    &-SignInButton {
        width: 100%;
    }

    &-Input {
        &_type_email {
            width: 100%;
        }
    }

    &-InnerWrapper {
        @include mobile {
            padding-top: 20px;
        }

        @include desktop {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;
            width: 100%;
            margin: 0 auto;
        }

        .Field-Wrapper {
            &:first-of-type {
                margin-top: 0;
            }
        }

        .MyAccountOverlay {
            &-Additional {
                display: none;
            }
        }

        button,
        input {
            width: 100%;
        }

        h3 {
            text-align: center;
            margin-bottom: 20px;
        }

        p {
            --body-paragraph-color: var(--color-gray2);

            letter-spacing: $letter-spacing;
            font-size: 12px;
            line-height: 15px;
            margin-block-end: 20px;
        }
    }

    &-ContainerWrapper {
        @include desktop {
            flex: 1;
            max-width: 510px;
            padding-top: 25px;
            padding-bottom: 25px;
            padding-right: 90px;
        }
    }

    &-AdditionalContent {
        @include desktop {
            flex: 1;
            padding-left: 90px;
            max-width: 510px;
            padding-top: 25px;
            padding-bottom: 25px;
            border-left: 1px solid var(--primary-divider-color);
        }
    }

    &-SignInWrapper {
        margin-top: 40px;
    }

    .Loader {
        margin: 0;
    }
}
