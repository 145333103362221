/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/mixins';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

.ResetAttributes {
    @include mobile {
        padding-left: 10px;
        padding-right: 10px;
    }

    @include desktop {
        margin-bottom: 25px;
    }

    > div {
        &:last-of-type {
            .ResetAttributes-AttributeValue {
                margin-bottom: 0;
            }
        }
    }

    &-AttributeValue {
        margin-bottom: 15px;
        display: flex;
        align-items: center;
    }

    &-AttributeText {
        font-size: 14px;
        line-height: 18px;
        letter-spacing: 1.05px;
        color: var(--primary-light-color);
    }

    &-AttributeLabel {
        font-weight: $font-weight-semibold;
    }

    &-CloseIcon {
        margin-right: 10px;

        .CloseIcon {
            &::before {
                font-size: 15px;
                line-height: 15px;
            }
        }

        &:hover {
            @include desktop {
                .CloseIcon {
                    &::before {
                        color: var(--primary-base-color);
                    }
                }
            }
        }
    }

    &-Title {
        margin-bottom: 20px;

        @include mobile {
            margin-top: 20px;
            padding-left: 10px;
            padding-right: 10px;
        }

        @include desktop {
            border-top: 1px solid var(--expandable-content-divider-color);
            text-transform: none;
            padding-top: 25px;
        }
    }

    &-MobileTitle {
        @include mobile {
            padding-inline-start: 16px;
            font-weight: 700;
            padding-block-start: 20px;
            display: none;
        }

        @include desktop {
            display: none;
        }
    }
}
