/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../../src/style/abstract/variables';
@import '../../../../../src/style/abstract/mixins';
@import '../../../../../src/style/abstract/media';
@import '../../../../../src/style/abstract/button';
@import '../../../../../src/style/abstract/loader';
@import '../../../../../src/style/abstract/icons';
@import '../../../../../src/style/abstract/parts';

/**
 * KemanaPWA - Progressive Web App for Magento
 * Copyright © Kemana, Inc. All rights reserved.
 *
 * @package kemana-gift-wrapper
 * @author ashangunawardena
 */

.GiftWrapper {
    &-GiftWrapButton {
        @include sub-heading-2;
        @include gift-wrapper-icon;

        font-weight: $font-weight-medium;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        cursor: pointer;

        &:hover {
            @include desktop {
                color: var(--primary-base-color);
            }
        }

        &::before {
            margin-right: 10px;
        }
    }

    &-CartItem {
        display: flex;
        flex-wrap: wrap;

        &Wrap,
        &Card {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            margin-bottom: 10px;

            .Image {
                width: 30px;
                height: 30px;
                padding-bottom: 0;
                border: 1px solid var(--secondary-divider-color);
            }

            &Title {
                font-size: 12px;
                font-weight: $font-weight-medium;
                letter-spacing: $letter-spacing1;
                color: var(--secondary-light-color);
                margin-left: 10px;
                min-width: 101px;

                @include mobile {
                    min-width: 92px;
                }
            }

            &Value {
                font-size: 12px;
                letter-spacing: $letter-spacing;
                color: var(--color-gray2);

                @include desktop {
                    width: calc(100% - 142px);
                }
            }
        }
    }

    &-EditButton {
        @include edit-icon;

        width: 20px;

        @include mobile {
            width: 20px;
        }

        &::before {
            transition: $transition2;
        }

        span {
            display: none;
        }

        &:hover {
            @include desktop {
                text-decoration: none;
            }

            &::before {
                @include desktop {
                    color: var(--primary-base-color);
                }
            }
        }

        &:focus {
            @include desktop {
                text-decoration: none;
            }
        }
    }

    &-DeleteButton {
        @include remove-icon;

        @include mobile {
            width: 20px;
        }

        width: 20px;
        margin-left: 10px;

        &::before {
            transition: $transition2;
            line-height: 20px;
        }

        span {
            display: none;
        }

        &:hover {
            @include desktop {
                text-decoration: none;
            }

            &::before {
                @include desktop {
                    color: var(--primary-base-color);
                }
            }
        }

        &:focus {
            @include desktop {
                text-decoration: none;
            }
        }
    }
}
