/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/mixins';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

:root {
    --menu-item-height: auto;
    --menu-total-height: 59px;
}

@mixin subcategory-visible {
    pointer-events: all;
    z-index: 10;
    opacity: 1;
    transform: translateX(0);
}

.Menu {
    &-MenuWrapper {
        @include desktop {
            background-color: var(--primary-base-color);
            min-height: 47px;
            transform: translateY(45px);
            transition: $transition2;
            z-index: -1;
        }

        @include print-only {
            display: none;
        }
    }

    &-CompareLink {
        color: var(--color-black);
        font-size: 14px;
        font-weight: $font-weight-regular;
        text-transform: none;
        display: flex;
        align-items: center;

        .CompareIcon {
            margin-right: 12px;

            &:hover {
                fill: var(--color-black);
            }
        }

        &Wrapper {
            border-block-end: 1px solid var(--primary-divider-color);
            padding-block-start: 17px;
            padding-block-end: 16px;
            padding-inline: 0;
            margin: 0 16px;

            @include mobile {
                padding: 13px 0;
            }
        }
    }

    &-CompareCountInMenu {
        font-style: normal;
        font-weight: $font-weight-bold;
        font-size: 12px;
        line-height: 16px;
        inset-block-start: -1px;
        margin-inline-start: auto;
    }

    &-ExpandedState {
        --plus-minus-icon-size: 18px;

        display: inline-block;
        float: inline-end;

        &:dir(rtl) {
            float: inline-start;
        }

        position: absolute;
        inset-inline-end: 0;

        @include desktop {
            display: none;
        }
    }

    &-Item {
        margin-block: 0;

        @include mobile {
            padding-inline: 10px;
        }

        &::before {
            content: none;
        }

        &Caption {
            --link-color: var(--primary-light-color);

            &:hover {
                @include desktop {
                    --link-hover: var(--primary-light-color);
                }
            }

            @include mobile {
                display: flex;
                align-items: center;
                justify-content: space-between;
                border-bottom: 2px solid var(--primary-divider-color);
                padding-block: 20px;
                cursor: pointer;
            }

            &_isSecondLevel {
                @include h6;

                @include mobile {
                    margin-bottom: 0;
                }
            }

            &_type {
                &_main {
                    text-transform: uppercase;
                    font-weight: $font-weight-medium;
                    font-size: 17px;
                    line-height: 20px;
                    letter-spacing: $letter-spacing6;
                    color: var(--primary-light-color);

                    @include desktop {
                        color: var(--color-white);
                    }

                    &:hover {
                        @include desktop {
                            color: var(--color-white);
                        }
                    }
                }

                &_subcategory {
                    @include mobile {
                        margin-inline-end: 0;
                    }

                    @include desktop {
                        margin-block-start: 0;
                        display: inline-block;
                    }
                }
            }
        }

        &List {
            @include desktop {
                display: flex;
                justify-content: space-around;
                max-width: $layout__max-width;
                margin: auto;

                .Menu-SubMenu & {
                    display: block;
                }
            }

            &_type_main {
                @include mobile {
                    border-bottom: 2px solid var(--primary-divider-color);
                }
            }

            &_type_subcategory {
                @include mobile {
                    padding: 0 10px;
                    height: calc(100vh - 120px);
                    padding-bottom: 0;
                    overflow-y: auto;
                    width: 100%;
                }
            }
        }

        &:last-of-type {
            @include mobile {
                margin-bottom: 0;
            }

            @include desktop {
                margin-block-end: 0;
            }

            .Menu-ItemCaption_type_main {
                @include mobile {
                    border-bottom: 0;
                }
            }
        }
    }

    &-SubCatLink,
    &-Link {
        .Menu-ItemCaption {
            @include mobile {
                @include arrow-right-icon;
            }
        }
    }

    &-Link {
        font-weight: $font-weight-regular;
        margin-block: 8px;
        margin-inline: 0 16px;

        .Menu-ItemCaption {
            @include mobile {
                &::after {
                    display: none;
                }
            }
        }
    }

    &-MainCategories {
        margin: 0 auto;

        .Menu {
            &-Link {
                @include desktop {
                    display: flex;
                    flex-wrap: wrap;
                    margin: 0;
                    padding: 13px 30px 14px;
                }

                &:hover {
                    @include desktop {
                        background-color: var(--primary-dark-color);
                    }
                }

                &_isHovered {
                    @include desktop {
                        background-color: var(--primary-dark-color);
                    }
                }
            }
        }
    }

    &-SubCategories {
        @include desktop {
            padding-inline: 20px;
            padding-block-end: 30px;
            padding-block-start: 30px;
            max-height: 520px;
            overflow-y: auto;

            @include custom-scroll;
        }

        &Wrapper {
            &_isVisible {
                @include desktop {
                    position: absolute;
                    width: 100%;
                }
            }
        }

        &WrapperInner {
            @include desktop {
                max-width: 1340px;
                margin: auto;
                box-shadow: $box-shadow1;
                background: var(--body-background-color);
            }
        }
    }

    &-Sub {
        &ItemWrapper {
            > .Menu-Link {
                @include desktop {
                    @include h5;
                }

                &:hover {
                    @include desktop {
                        color: var(--primary-base-color);
                    }

                    .Menu-ItemCaption {
                        @include desktop {
                            color: var(--primary-base-color);
                        }
                    }
                }

                .Menu-ItemCaption {
                    color: var(--primary-dark-color);
                    transition: $transition1;
                    margin-bottom: 15px;
                }
            }

            @include mobile {
                break-inside: avoid;
                overflow: hidden;
            }

            @include desktop {
                width: 25%;
            }
        }

        &Menu {
            display: none;
            margin-block-end: 28px;

            @include mobile {
                opacity: 0;
                pointer-events: none;
                touch-action: pan-y;
                transform: translateX(100%);
                transition: transform .2s ease-in-out;
                height: 100%;
                left: 0;
                padding-top: var(--header-total-height);
                padding-bottom: var(--footer-nav-height);
                position: fixed;
                top: 0;
                width: 100%;
                background: var(--color-white);
                overflow-y: hidden;
            }

            &_isVisible {
                background: var(--color-gray);
                display: block;
                padding-inline: 16px;

                @include mobile {
                    pointer-events: all;
                    z-index: 10;
                    opacity: 1;
                    transform: translateX(0);
                    padding-inline: 0;
                    margin-block-end: 0;
                    background: var(--color-white);
                }
            }

            @include desktop {
                background: none;
                display: block;
                padding: 0;
            }

            .Menu-SubMenu & {
                padding-block-end: 0;
            }

            .Menu-ItemCaption {
                @include mobile {
                    margin-inline: 0;
                }

                @include desktop {
                    padding-block-end: 0;
                }
            }

            .Menu-ItemList {
                @include desktop {
                    justify-content: flex-start;
                    margin-bottom: 30px;
                }

                &_type_subcategory {
                    .Menu-Link {
                        .Menu-ItemCaption {
                            @include mobile {
                                @include h6;
                            }

                            &_type_subcategory {
                                @include mobile {
                                    margin-bottom: 0;
                                }
                            }
                        }
                    }
                }

                > div {
                    &:last-of-type {
                        .Menu-ItemCaption {
                            @include mobile {
                                border-bottom: 0;
                            }
                        }
                    }
                }
            }

            > .Menu-ItemList {
                @include desktop {
                    display: flex;
                    flex-wrap: wrap;
                }
            }
        }
    }

    &-SubLevelDesktop {
        .Menu-ItemList {
            .Menu-Link {
                --link-color: var(--primary-light-color);

                @include h6;

                &:hover {
                    @include desktop {
                        --link-hover: var(--primary-light-color);
                    }

                    .Menu-ItemCaption {
                        @include desktop {
                            text-decoration: underline;
                        }
                    }
                }
            }

            .Menu-ItemCaption {
                margin-bottom: 10px;
            }
        }
    }

    &-Switcher {
        background-color: var(--color-gray5);
        padding-inline: 10px;
    }
}
