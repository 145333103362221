/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../../src/style/abstract/variables';
@import '../../../../../src/style/abstract/mixins';
@import '../../../../../src/style/abstract/media';
@import '../../../../../src/style/abstract/button';
@import '../../../../../src/style/abstract/loader';
@import '../../../../../src/style/abstract/icons';
@import '../../../../../src/style/abstract/parts';

:root {
    --shipping-payment-logo-width: 66px;
}

.ShippingPaymentLogo {
    width: var(--shipping-payment-logo-width);
    padding: 1px;
    order: 2;

    &-Logo {
        width: var(--shipping-payment-logo-width);
        margin-right: 2rem;

        .Image {
            padding-bottom: inherit;
            height: inherit;
            overflow: visible;
        }

        .Image-Image {
            position: static;
            width: auto !important;
            height: auto !important;
        }
    }
}

.CheckoutPayments-Methods {
    .CheckoutPayment-Button {
        align-items: center;
    }
}
