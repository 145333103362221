/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/mixins';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

:root {
    --navigation-tabs-height: calc(50px + env(safe-area-inset-bottom));
    --navigation-tabs-background: var(--primary-base-color);
    --navigation-tabs-color: var(--secondary-dark-color);
}

.NavigationTabs {
    --header-background: var(--navigation-tabs-background);
    --header-icon-stroke-width: 2px;

    position: fixed;
    inset-block-end: 0;
    inset-inline-start: 0;
    width: 100%;
    z-index: 100;
    background-color: var(--header-background);
    padding-block-end: env(safe-area-inset-bottom);
    transform: translateY(0);
    transition: transform 200ms cubic-bezier(.47, 0, .745, .715);

    .hideOnScroll & {
        transform: translateY(100%);
    }

    @include desktop {
        display: none;
    }

    @include print-only {
        display: none;
    }

    &_isHidden {
        display: none;

        @at-root .hiddenNavigationTabs {
            --navigation-tabs-height: env(safe-area-inset-bottom);
        }
    }

    &-Nav {
        height: var(--footer-nav-height);
        display: flex;
        align-items: center;
        justify-content: space-between;
        z-index: 20;
        max-width: var(--content-wrapper-width);
        margin: auto;
    }

    &-Button {
        flex-grow: 1;
        height: 100%;
        display: inherit;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        &_type {
            &_home {
                @include home-icon;
            }

            &_menu {
                @include menu-icon;
            }

            &_wishlist {
                @include wishlist-icon;

                &::before {
                    color: var(--color-white);
                    font-size: 24px;
                    line-height: 24px;
                }
            }

            &_minicart {
                @include minicart-icon;

                &::before {
                    color: var(--color-white);
                    font-size: 23px;
                    line-height: 23px;
                }

                .Header-MinicartItemCount {
                    background-color: var(--color-white);
                    color: var(--primary-base-color);

                    @include mobile {
                        top: -16px;
                        right: -8px;
                    }
                }
            }

            &_account {
                @include account-icon;

                &::before {
                    color: var(--color-white);
                    font-size: 23px;
                    line-height: 23px;
                }
            }
        }
    }

    &-Icon {
        max-width: 72px;
        width: 100%;

        &_isActive {
            background-color: var(--color-white);

            &::before {
                color: var(--primary-base-color);
            }

            .Header-MinicartItemCount {
                background-color: var(--primary-base-color);
                color: var(--color-white);
            }
        }
    }
}
