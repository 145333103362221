/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/mixins';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

.PasswordChangePage {
    margin-bottom: 25px;
    margin-top: 0;
    padding-top: 20px;

    @include desktop() {
        padding-top: 80px;
        padding-bottom: 105px;
        margin-top: -12px;
        background-color: var(--color-gray5);
    }

    &-Wrapper {
        @include desktop() {
            max-width: 360px;
            margin: 0 auto;
            box-shadow: $box-shadow9;
            background-color: var(--color-white);
            padding: 25px 20px;
        }

        .Field-Wrapper {
            &:first-of-type {
                @include mobile {
                    margin-top: 0;
                }
            }
        }

        h3 {
            text-align: center;
        }

        input {
            width: 100%;
            height: 100%;
        }

        button {
            @include button;

            width: 100%;
        }
    }
}
