/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/mixins';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

:root {
    --help-switcher-width: 312px;
    --help-switcher-height: 183px;
}

.HelpSwitcher {
    @include desktop {
        border-right: 1px solid var(--color-gray3);
        padding: 0 15px;
    }

    &-Title {
        @include paragraph-text;
        @include arrow-down-icon;

        line-height: 19px;
        color: var(--color-gray9);
        letter-spacing: $letter-spacing;
        cursor: pointer;
        transition: $transition1;
        display: flex;
        align-items: center;

        @include mobile {
            @include arrow-right-icon;

            justify-content: space-between;
            border-bottom: 2px solid var(--primary-divider-color);
            font-weight: $font-weight-medium;
            padding-block: 20px;
            cursor: pointer;
        }

        &::after {
            @include desktop {
                margin-left: 5px
            }
        }

        &_isActive {
            @include desktop {
                @include arrow-up-icon;
            }

            span {
                @include desktop {
                    text-decoration: underline;
                }
            }
        }
    }

    &-HelpSwitcherList {
        @include desktop {
            position: absolute;
            opacity: 0;
            visibility: hidden;
            min-width: var(--help-switcher-width);
            min-height: var(--help-switcher-height);
        }

        @include mobile {
            opacity: 0;
            pointer-events: none;
            touch-action: pan-y;
            transform: translateX(100%);
            transition: transform .2s ease-in-out;
            height: 100%;
            left: 0;
            padding-top: var(--header-total-height);
            padding-bottom: var(--footer-nav-height);
            position: fixed;
            top: 0;
            width: 100%;
            background: var(--color-white);
            overflow-y: hidden;
        }

        &_isActive {
            @include desktop {
                right: -48px;
                opacity: 1;
                visibility: visible;
                z-index: 1;
                margin-top: 9px;
                min-width: var(--help-switcher-width);
                box-shadow: $box-shadow1;
            }

            @include mobile {
                pointer-events: all;
                z-index: 10;
                opacity: 1;
                transform: translateX(0);
                padding-inline: 0;
                margin-block-end: 0;
                background: var(--color-white);
            }

            &::before {
                @include desktop {
                    right: 88px;
                    content: '';
                    position: absolute;
                    inset-block-start: -6px;
                    height: 10px;
                    width: 10px;
                    transform: rotate(45deg);
                    background-color: var(--overlay-background);
                    margin: auto;
                    box-shadow: $box-shadow2;
                    z-index: 2;
                }
            }
        }

        ul {
            background-color: var(--overlay-background);

            @include mobile {
                padding-left: 10px;
                padding-right: 10px;
            }
            
            @include desktop {
                position: absolute;
                z-index: 3;
                padding-block-start: 10px;
                min-width: var(--help-switcher-width);
            }

            li {
                margin-bottom: 0;

                h5 {
                    padding-block-end: 10px;
                    padding-block-start: 10px;
                    padding-left: 20px;
                    padding-right: 20px;
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;
                    width: 100%;
                    letter-spacing: 0;
                    color: var(--color-black);

                    @include mobile {
                        display: none;
                    }
                }

                &::before {
                    display: none;
                }
            }
        }
    }

    &-Values {
        &:last-of-type {
            .HelpSwitcher-HelpItem {
                @include mobile {
                    border-bottom: 0;
                }
            }
        }
    }

    &-HelpItem {
        @include paragraph-text;

        padding-block-end: 13px;
        padding-block-start: 12px;
        line-height: 19px;
        color: var(--color-gray9);
        letter-spacing: $letter-spacing;
        font-weight: $font-weight-medium;
        cursor: pointer;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        width: 100%;

        @include mobile {
            padding-block-end: 20px;
            padding-block-start: 20px;
            border-bottom: 2px solid var(--primary-divider-color);
        }

        @include desktop {
            padding-left: 20px;
            padding-right: 10px;
        }

        span {
            color: var(--color-gray9);
        }

        &:hover {
            @include desktop {
                background-color: var(--color-grey11);
            }
        }
    }

    &-Faq {
        @include faq-icon;

        &::before {
            margin-right: 10px;
            width: 20px;
        }
    }
    
    &-Email {
        @include email-icon;

        a {
            --link-color: var(--link-hover);

            font-weight: $font-weight-medium;
            letter-spacing: $letter-spacing;
        }

        &::before {
            margin-right: 10px;
            color: var(--primary-light-color);
            line-height: 18px;
            width: 20px;
        }
    }

    &-LiveChat {
        @include chat-icon;

        &::before {
            margin-right: 10px;
            color: var(--primary-light-color);
            width: 20px;
        }
    }
}
