/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../../src/style/abstract/variables';
@import '../../../../../src/style/abstract/mixins';
@import '../../../../../src/style/abstract/media';
@import '../../../../../src/style/abstract/button';
@import '../../../../../src/style/abstract/loader';
@import '../../../../../src/style/abstract/icons';
@import '../../../../../src/style/abstract/parts';

/**
 * KemanaPWA - Progressive Web App for Magento
 * Copyright © Kemana, Inc. All rights reserved.
 *
 * @package kemana-blog
 * @author ashangunawardena
 */

.BlogList {
    &-List {
        display: grid;

        @include desktop {
            grid-template-columns: repeat(3, 1fr);
            grid-gap: 0 20px;
        }
    }

    &-ListItem {
        margin-bottom: 50px;

        @include desktop {
            margin-bottom: 80px;
        }

        img {
            @include desktop {
                height: 163px;
            }

            height: 190px;
            object-fit: cover;
        }
    }

    &-Pagination {
        .Pagination {
            display: flex;
        }

        nav {
            @include mobile {
                margin-bottom: 50px;
            }
        }
    }

    &-ListItemContentContainer {
        h3 {
            margin: 20px 0;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            text-overflow: ellipsis;
        }
    }

    &-ListItemCreatedDate {
        p {
            line-height: 19px;
            font-weight: $font-weight-medium;
            letter-spacing: $letter-spacing1;
            color: var(--color-gray2);
        }
    }

    &-ListItemDescription {
        p {
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            text-overflow: ellipsis;
        }
    }

    &-ListItemLink {
        line-height: 22px;
    }
}
