/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/mixins';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

:root {
    --newsletter-subscription-input-background: var(--input-background-color);
    --newsletter-subscription-placeholder-color: var(--color-gray2);
    --newsletter-subscription-button-background: var(--link-color);
}

.NewsletterSubscription {
    min-width: 230px;

    .FieldForm {
        &-Fieldset {
            &_isLoading {
                opacity: .7;
                pointer-events: none;
            }

            .Field {
                width: 100%;
                margin-block-start: 0;

                &-Wrapper {
                    margin-top: 0;
                } 

                &-Message {
                    position: absolute;
                }

                &-SuccessMessages {
                    color: var(--color-green);
                    letter-spacing: $letter-spacing1;
                    margin-top: 5px;
                    position: absolute;
                }

                &-ErrorMessages {
                    color: var(--color-error);
                    margin-top: 5px;
                    letter-spacing: $letter-spacing1;
                    position: absolute;
                }               

                input {
                    background-color: var(--newsletter-subscription-input-background);
                    padding: 0;
                    height: 45px;
                    border: 0;
                    border-bottom: 1px solid var(--secondary-divider-color);
                    font-size: 14px;
                    line-height: 17px;
                    font-weight: $font-weight-regular;
                    letter-spacing: $letter-spacing1;

                    &::placeholder {
                        color: var(--newsletter-subscription-placeholder-color);
                    }

                    @include mobile {
                        height: 40px;
                    }

                    @include desktop {
                        width: 440px;
                        margin-top: 5px;
                    }
                }

                &_hasError {
                    input {
                        border-bottom: 1px solid var(--input-border-color);
                    }
                }
            }
        }

        &-Fields {
            margin-block-end: 0;
        }
    }

    .Form {
        &_isInvalid {
            margin-block-end: 18px;
        }
    }

    .Button {
        --hollow-button-border: var(--color-white);
        --default-button-letter-spacing: #{$letter-spacing8};
        --button-padding: 0 15px;
        --button-border-width: 0;

        position: absolute;
        top: 0;
        right: 0;
        width: auto;

        @include desktop {
            top: 7px;
        }

        &:not([disabled]) {
            @include desktop {
                &:hover {
                    --hollow-button-hover-background: var(--color-white);
                    --hollow-button-hover-border: var(--color-white);
                    --hollow-button-hover-color: var(--primary-base-color);
                }
            }
        }
    }
}
