/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

:root {
    --default-input-padding: 15px 20px 14px;
    --input-background-color: var(--imported_input_background_color, #fff);
    --input-border-color: var(--primary-divider-color);
    --input-border-color-focus: var(--imported_input_border_color, var(--color-gray1));
    --input-border-radius: 0;
    --input-padding: 10px 15px 11px;
    --input-color: var(--primary-light-color);
    --input-placeholder-color: var(--color-gray8);
    --input-type-number-width: 28px;
    --input-line-height: 38px;
}

input,
textarea,
select {
    border: 1px solid var(--input-border-color);
    background-color: var(--input-background-color);
    border-radius: var(--input-border-radius);
    color: var(--input-color);
    padding: var(--input-padding);
    appearance: none;
    caret-color: var(--input-color);
    letter-spacing: $letter-spacing1;

    &:focus {
        border: 1px solid var(--input-border-color-focus);
    }

    &:not(:first-of-type) {
        margin-block-start: 12px;

        @include mobile {
            margin-block-start: 14px;
        }
    }

    &:-webkit-autofill {
        /* stylelint-disable-next-line declaration-no-important */
        box-shadow: 0 0 0 30px var(--input-background-color) inset !important;
        /* stylelint-disable-next-line declaration-no-important */
        background-color: var(--input-background-color) !important;
    }
}

input {
    &::-ms-reveal {
        display: none;
    }
}

textarea {
    width: 100%;
}

[type='text'] {
    @include mobile {
        width: 100%;
    }
}

[type='number'] {
    -moz-appearance: textfield;
    color: var(--primary-light-color);
    border: 0;
    font-size: 14px;
    font-weight: $font-weight-semibold;
    letter-spacing: $letter-spacing1;
    height: var(--input-type-number-width);
    padding: 0;
    order: 2;
    text-align: center;
    width: var(--input-type-number-width);
    pointer-events: none;

    &:focus {
        border: 1px solid var(--input-border-color);
    }

    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
        appearance: none;
        margin: 0;
    }
}
