/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../../src/style/abstract/variables';
@import '../../../../../src/style/abstract/mixins';
@import '../../../../../src/style/abstract/media';
@import '../../../../../src/style/abstract/button';
@import '../../../../../src/style/abstract/loader';
@import '../../../../../src/style/abstract/icons';
@import '../../../../../src/style/abstract/parts';

/**
 * KemanaPWA - Progressive Web App for Magento
 * Copyright © Kemana, Inc. All rights reserved.
 *
 * @package kemana-free-gift
 * @author ashangunawardena
 */

.FreeGifts {
    &-Wrapper {
        padding-left: 10px;
        padding-right: 10px;

        @include desktop-xxl {
            padding-left: 0;
            padding-right: 0;
        }

        .Button_isGiftWrappers {
            --default-button-text-transform: normal;
            --default-button-font-size: 20px;
            --default-button-letter-spacing: #{$letter-spacing4};

            width: 100%;
            font-size: 20px;

            @include mobile {
                margin-top: 20px;
            }

            @include desktop {
                --button-height: 50px;

                margin-bottom: 30px;
            }
        }
    }
}
