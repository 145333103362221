/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/mixins';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.InstallPrompt {
    border-top: 0;
    position: fixed;
    top: 0;
    width: 100%;
    margin: 0;
    z-index: 2;

    ~ .NotificationList {
        top: calc(var(--header-nav-height) + 147px);
    }

    ~ .Header-Wrapper {
        .Header_name_default {
            @include mobile {
                top: 50px;
            }
        }
    }

    @include mobile {
        + .CmsPage {
            margin-top: 0;

            .CmsPage-Wrapper {
                margin-top: 0;
            }
        }
    }
}
