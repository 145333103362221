/**
 * KemanaPWA - Progressive Web App for Magento
 *
 * Copyright © Kemana, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

.home-product-widget {
    margin-block-end: 50px;

    @include mobile {
        margin-block-end: 60px;
    }

    [data-content-type="heading"] {
        @include mobile {
            padding-inline: 10px;
            min-height: 29px;
        }

        @include desktop {
            padding-inline: 10px;
        }

        @include desktop-xxl {
            padding-inline: 0;
            min-height: 39px;
        }
    }
}
