/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/mixins';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

:root {
    --search-bar-max-width: 450px;
    --search-bar-width: 270px;
    --search-clear-background: var(--secondary-base-color);
    --search-field-background: var(--secondary-base-color);
    --search-field-border-color: var(--primary-divider-color);
    --search-field-color: var(--secondary-base-color);
    --search-field-visible-background: #fff;
    --search-input-desktop-border-color: #000;
}

.SearchField {
    display: none;
    margin: 0 auto;
    max-width: 100%;
    width: 100%;

    @include desktop {
        display: block;
        width: 32%;
        max-width: 610px;
    }

    @include desktop-xl {
        width: 42%;
    }

    @include desktop-xxl {
        width: 55%;
    }

    &_isVisible {
        display: block;

        @include print-only {
            display: none;
        }
    }

    &_isActive {
        .SearchOverlay {
            &-Results {
                display: block;
            }
        }
    }

    &-CloseIcon,
    &-SearchIcon {
        position: absolute;
        height: 20px;
        right: 15px;
        inset-block-start: calc(50% - 10px);
        width: 20px;
    }

    &-Input {
        width: 100%;

        &:focus {
            border-color: var(--input-border-color);
        }
    }

    &-SearchInnerWrapper {
        input {
            &::-webkit-input-placeholder {
                color: var(--input-placeholder-color);
            }
        }
    }
}
