/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../../src/style/abstract/variables';
@import '../../../../../src/style/abstract/mixins';
@import '../../../../../src/style/abstract/media';
@import '../../../../../src/style/abstract/button';
@import '../../../../../src/style/abstract/loader';
@import '../../../../../src/style/abstract/icons';
@import '../../../../../src/style/abstract/parts';

.PaymentCallbackPage {
    &-Wrapper {
        background: var(--color-grey17);
        padding-bottom: 50px;
        min-height: calc(100vh - 111px);
        
        @include desktop {
            margin-top: var(--header-nav-height);
            padding-top: 100px;
        }

        img {
            width: 100px;
        }
        
        + .Footer {
            .Footer-Promo,
            .Footer-Subscription,
            .Footer-Content {
                display: none;
            }
        }
    }

    &-Container {
        --content-wrapper-width: 1100px;

        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        grid-gap: 0;
        width: 100%;
        margin: auto;

        @include mobile {
            padding-top: 20px;
        }
    }

    &-InnerContainer {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

        @include desktop {
            max-width: 618px;
            margin: auto;
        }

        @include mobile {
            margin-top: 30px;
            padding-left: 20px;
            padding-right: 20px;
        }

        img {
            width: 200px;
            height: 200px;
            margin-bottom: 30px;

            @include desktop {
                width: 180px;
                height: 180px;
            }
        }

        p {
            width: 100%;
            text-align: center;

            @include desktop {
                max-width: 618px;
            }

            &:last-of-type {
                margin-bottom: 0;
            }

            .Button {
                &_likeLink {
                    @include mobile {
                        width: auto;
                    }
                }
            }
        }
    }

    &-PaymentCallbackPending, 
    &-PaymentCallbackSplitOrderSuccess,
    &-PaymentCallbackLoading,
    &-PaymentCallbackSuccess,
    &-PaymentCallbackInvalid,
    &-PaymentCallbackFailed {
        text-align: center;
    }

    &-PaymentCallbackSuccess {
        h4 {
            margin-bottom: 20px;
        }
    }

    &-ButtonWrapper {
        @include mobile {
            display: flex;
            flex-wrap: wrap;
        }

        @include desktop {
            margin-top: 40px;
            text-align: center;
        }

        @include mobile {
            padding: 10px 15px;
            position: fixed;
            inset-inline-start: 0;
            bottom: var(--footer-nav-height);
            background: var(--body-background-color);
            box-shadow: $box-shadow11;
            width: 100%;
        }

        .CheckoutSuccess {
            &-ContinueButton,
            &-CreateAccountButton {
                @include desktop {
                    --button-padding: 0 45px;

                    margin: 0 5px;
                }
            }

            &-ContinueButton {
                @include mobile {
                    order: 2;
                }
            }

            &-CreateAccountButton {
                @include mobile {
                    order: 1;
                    margin-bottom: 15px;
                }
            }
        }
    }
}
