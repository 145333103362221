/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../../src/style/abstract/variables';
@import '../../../../../src/style/abstract/mixins';
@import '../../../../../src/style/abstract/media';
@import '../../../../../src/style/abstract/button';
@import '../../../../../src/style/abstract/loader';
@import '../../../../../src/style/abstract/icons';
@import '../../../../../src/style/abstract/parts';

/**
 * KemanaPWA - Progressive Web App for Magento
 * Copyright © Kemana, Inc. All rights reserved.
 *
 * @package kemana-blog
 * @author ashangunawardena
 */

.BlogsPage {
    padding-bottom: 90px;
    margin-top: 80px;

    @include desktop {
        padding-bottom: calc(var(--header-nav-height) - 5px);
        margin-top: 0;
    }

    &-Container {
        display: grid;

        @include desktop {
            justify-content: space-between;
            grid-template-columns: 2.8fr 1fr;
            grid-gap: 50px;
        }
    }

    &-Sidebar {
        &Inner {
            border: 1px solid var(--color-grey16);
            padding: 25px 20px 20px;
        }

        h4 {
            margin-bottom: 20px;
        }
    }
}
