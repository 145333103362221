/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../../src/style/abstract/variables';
@import '../../../../../src/style/abstract/mixins';
@import '../../../../../src/style/abstract/media';
@import '../../../../../src/style/abstract/button';
@import '../../../../../src/style/abstract/loader';
@import '../../../../../src/style/abstract/icons';
@import '../../../../../src/style/abstract/parts';

/**
 * KemanaPWA - Progressive Web App for Magento
 * Copyright © Kemana, Inc. All rights reserved.
 *
 * @package kemana-store-locator
 * @author ashangunawardena
 */

.StoreLocator {
    &-Container {
        --google-map-height: 567px;

        margin-bottom: 20px;
        position: relative;

        @include mobile {
            --footer-nav-height: 75px;
            --google-map-height: 350px;

            margin-bottom: var(--footer-nav-height);
        }

        @include desktop {
            margin-bottom: calc(var(--header-nav-height) - 5px);
        }
    }

    &-Title {
        @include mobile {
            display: none;
        }
    }

    &-SearchBox {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        margin-bottom: 30px;

        @include mobile {
            padding-top: 20px;
        }

        &Title {
            width: 100%;
            font-size: 14px;
            letter-spacing: $letter-spacing1;
            margin-bottom: 10px;
        }
    }

    &-AutocompleteContainer {
        width: 100%;

        @include mobile {
            margin-bottom: 15px;
        }

        @include desktop {
            max-width: 322px;
            padding-right: 20px;
        }

        @include desktop-xl {
            max-width: 425px;
        }

        @include desktop-xxl {
            max-width: 505px;
        }

        .Field {
            &-Wrapper {
                &:first-of-type {
                    margin-top: 0;
                }
            }
        }
    }

    &-DistanceSelectWrapper {
        @include mobile {
            width: 100%;
            margin-bottom: 25px;
        }

        @include desktop {
            padding-right: 20px;
            min-width: 234px;
        }

        .Field {
            margin-top: 0;

            &-Wrapper {
                &:first-of-type {
                    margin-top: 0;
                }
            }

            &Select {
                &-Select {
                    font-size: 14px;
                    letter-spacing: $letter-spacing1;
                }

                &-Options {
                    &_isExpanded {
                        @include desktop {
                            @include custom-scroll;

                            max-height: 174px;

                            &::-webkit-scrollbar-track {
                                background: var(--color-white);
                            }
                        }
                    }
                }

                &-Option {
                    letter-spacing: $letter-spacing1;
                    font-size: 14px;
                    max-height: 34px;
                    line-height: 2.1rem;
                }
            }
        }
    }

    &-SearchContainer {
        @include mobile {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            margin-bottom: 15px;
        }
    }

    &-Button {
        @include mobile {
            width: 100%;
        }
    }

    &-ButtonSeparator {
        padding: 0 20px;

        @include mobile {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            margin-bottom: 15px;
        }
    }

    &-LocateNearBy {
        @include mobile {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
        }
    }

    &-StoreResultContainer {
        display: flex;
        height: var(--google-map-height);

        @include mobile {
            height: auto;
            flex-wrap: wrap;
            order: 1;
        }

        @include desktop {
            display: grid;
            grid-template-columns: 385px auto;
            grid-column-gap: 2rem;
        }

        @include desktop-xxl {
            grid-template-columns: 485px auto;
        }
    }

    &-StoreLocatorMap {
        @include mobile {
            width: 100%;
            margin-bottom: 30px;
        }

        * {
            position: unset;
        }

        .gm-style-mot {
            position: relative;
        }
    }

    &-StoreList {
        height: var(--google-map-height);
        background-color: $white;
        overflow: auto;
        width: 100%;

        @include desktop {
            @include custom-scroll;

            &::-webkit-scrollbar-track {
                background: var(--color-white);
            }
        }

        @include mobile {
            height: 506px;
            order: 2;
        }
    }

    &-StoreListItem {
        border-bottom: 1px solid $grey13;
        padding: 0 20px 25px 0;
        margin-bottom: 25px;
        position: relative;
        cursor: pointer;

        &:last-of-type {
            margin-bottom: 0;
        }

        &:hover {
            @include desktop {
                z-index: 1;
            }
        }

        &_isStoreSelected{
            z-index: 1;
        }
    }

    &-StoreName {
        @include h4;

        margin-bottom: 20px;
    }

    &-StoreDistance {
        color: var(--color-gray2);
        font-size: 12px;
        letter-spacing: $letter-spacing;
        margin-bottom: 10px;
    }

    &-StoreAddress {
        @include h6;

        font-weight: $font-weight-regular;
        letter-spacing: $letter-spacing9;
        line-height: 22px;
        margin-bottom: 15px;
    }

    &-StorePhone {
        @include h6;

        font-weight: $font-weight-regular;
        letter-spacing: $letter-spacing9;
        line-height: 19px;
        margin-bottom: 15px;
        display: inline-block;
        width: 100%;

        @include phone-icon;

        &::before {
            font-size: 12px;
            margin-right: 10px;
        }

        &:hover {
            span {
                @include desktop {
                    text-decoration: underline;
                }
            }
        }
    }

    &-ScheduleTitle {
        @include h6;

        font-weight: $font-weight-regular;
        letter-spacing: $letter-spacing9;
        line-height: 19px;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        cursor: pointer;

        @include clock-icon;
        @include arrow-down-icon;

        &::before {
            font-size: 14px;
            margin-right: 10px;
        }

        &::after {
            font-size: .6rem;
            line-height: 14px;
            font-weight: $font-weight-bold;
            margin-left: 10px;
        }

        &_isScheduleListOpen {
            @include arrow-up-icon;

            &::after {
                font-size: .6rem;
                line-height: 9px;
            }
        }

        &_isScheduleDisabled{
            &::after{
                content: ''
            }
        }
    }

    &-ScheduleList {
        color: var(--color-gray2);
        flex-wrap: wrap;
        align-content: space-between;
        animation: $transition1;
        font-size: 12px;
        letter-spacing: $letter-spacing;
        display: none;

        &_isScheduleListOpen {
            display: flex;
            width: 100%;
            margin-top: 10px;
            padding-left: 25px;

            @include mobile {
                flex-wrap: wrap;
                max-width: 195px;
            }

            @include desktop-xxl {
                padding-right: 70px;
            }
        }
    }

    &-ScheduleWeekDayContainer {
        display: flex;
        width: 100%;
        justify-content: space-between;

        @include desktop {
            width: 50%;
            padding-right: 10px;
        }

        @include desktop-xxl {
            padding-right: 20px;
        }

        &:not(:last-child) {
            margin-bottom: 5px;
        }
    }

    &-ScheduleWeekDay{
        width: 48%;
    }

    &-EmptyStoreList {
        @include h6;
    }

    .LocateNearBy {
        &-Button {
            --button-padding: 0 24px;

            @include mobile {
                width: 100%;
            }
        }
    }
}
