/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/mixins';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.SalesLabel {
    &-SaleItem {
        color: var(--color-white);
        font-size: 10px;
        letter-spacing: $letter-spacing11;
        font-weight: $font-weight-bold;
        text-transform: uppercase;
        margin-bottom: 8px;
        background: var(--color-yellow3);
        padding: 6px 12px 7px;
        box-shadow: $box-shadow12;
        line-height: normal;
        width: fit-content;

        &_outOfStock {
            background: var(--color-grey14);
            color: var(--color-grey18);
        }

        &_bookSeries {
            background: var(--color-black);
            color: var(--color-white);
        }

        &:last-of-type {
            margin-bottom: 0;
        }
    }
}
