/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/mixins';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */
 
:root {
    --select-option-background: var(--body-background-color);
    --select-option-hover-background: var(--color-gray4);
}

$select-width: 160px !default;
$select-height: 45px !default;
$select-option-height: 44px !default;
$select-arrow-height: 14px !default;
$select-arrow-width: 14px !default;

.FieldSelect {
    @include mobile {
        position: relative;
    }

    &[aria-expanded="true"] {
        &::before {
            @include desktop {
                content: '';
                position: absolute;
                height: 3px;
                background-color: $white;
                border-bottom: 1px solid var(--input-border-color);
                top: 36px;
                left: 50%;
                transform: translateX(-50%);
                z-index: 233;
                width: 91%;
            }
        }
    }

    &-Clickable {
        cursor: pointer;
        display: flex;
        align-items: center;
        position: relative;

        #storeSwitcher,
        #CurrencySwitcherList {
            line-height: 19px;
        }

        @include arrow-down-large-icon;

        &::after {
            position: absolute;
            right: 15px;
        }
    }

    .ChevronIcon {
        width: $select-arrow-width;
        height: $select-arrow-height;
        pointer-events: none;
        position: absolute;
        inset-inline-end: 12px;
        display: none;
    }

    &_isExpanded {
        .FieldSelect-Clickable {
            @include arrow-up-large-icon;
        }
    }

    &-Select {
        --input-padding: 0 35px 0 15px;

        @include paragraph-text;

        width: 100%;
        min-width: 100%;
        letter-spacing: $letter-spacing9;
        color: var(--primary-light-color);
        line-height: var(--input-line-height);

        @include desktop {
            pointer-events: none;
        }

        &_isExpanded {
            border-color: var(--input-color);
        }
    }

    &-Options {
        position: absolute;
        width: 100%;
        top: 92%;
        left: 0;
        z-index: 40;
        border-width: 1px;
        border-style: solid;
        border-color: transparent;
        overflow-y: auto;
        transition: 200ms max-height;
        will-change: max-height;
        max-height: 0;
        -webkit-overflow-scrolling: touch;

        &_isExpanded {
            @include desktop {
                @include custom-scroll;

                max-height: 168px;
                background-color: var(--select-option-background);
                border-color: var(--input-border-color);
                border-top: 0;
                padding-top: 3px
            }
        }
    }

    &-Option {
        @include paragraph-text;

        letter-spacing: $letter-spacing9;
        color: var(--primary-light-color);
        padding: 0;
        margin: 0;
        z-index: 1;
        transition: 100ms max-height ease-in;
        will-change: max-height;
        line-height: 2.2rem;
        padding-top: 7px;
        padding-bottom: 6px;
        padding-left: 1.5rem;
        background: var(--select-option-background);
        cursor: pointer;

        @include mobile {
            line-height: 4.2rem;
            padding-left: 2.1rem;
        }

        &::before {
            content: none;
        }

        &:last-of-type {
            margin: 0;
        }

        &:hover {
            @include desktop {
                background: var(--select-option-hover-background);
            }
        }
    }

    &_hasNoOptions {
        .FieldSelect {
            &-Select {
                border: 1px solid var(--primary-divider-color);
                background: var(--color-gray5);
                color: var(--color-gray8);
            }

            &-Clickable {
                &::after {
                    color: var(--color-gray8);
                }
            }
        }
    }
}
