/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/mixins';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

.FieldForm {
    &-Body {
        label {
            @include desktop {
                min-width: calc(100% - 30px);
            }
        }

        input {
            width: 100%;

            + label {
                min-width: auto;
            }
        }
    }

    &-Legend {
        @include legend-form-border;

        margin-bottom: 0;
    }

    &-Fields {
        margin-bottom: 30px;

        .Field {
            &-Wrapper {
                &_type_checkbox {
                    .Field-CheckboxLabel {
                        padding-bottom: 0;
                    }
                }
            }
        }
    }
}
