/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/mixins';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

.Breadcrumb {
    $crumb-padding: 15px;
    $crumb-margin: 10px;

    display: inline-block;
    padding-right: $crumb-padding;
    margin-right: $crumb-margin;
    line-height: 17px;

    &:not(:last-child) {
        &::after {
            content: '/';
            position: absolute;
            right: 0;
            color: var(--color-gray8);
        }
    }

    &:last-of-type {
        margin-bottom: 0;
        margin-right: 0;
        padding-right: 0;

        .Breadcrumb-Link {
            pointer-events: none;
            color: var(--color-gray8);
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            max-width: 200px;
        }
    }

    &::before {
        content: none;
    }

    &-Link {
        color: var(--breadcrumbs-color);
        display: inline-block;
        font-weight: $font-weight-regular;
        font-size: 14px;
        line-height: 17px;
        letter-spacing: $letter-spacing4;

        &:hover {
            @include desktop {
                text-decoration: underline;
            }
        }

        span {
            color: inherit;
        }
    }
}
