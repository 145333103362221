/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

:root {
    --homepage-slider-color: #{$white};
}

.home-page-slider {
    margin-block-end: 60px;

    @include desktop {
        margin-block-end: 70px;
    }

    [data-element="main"] {
        > p {
            margin-bottom: 0;
        }
    }
}

.homepage-slider {
    h1 {
        margin-block-end: 30px;

        @include mobile {
            font-size: 30px;
            margin-block-end: 14px;
        }
    }

    .Slider-Crumbs {
        inset-block-end: 24px;

        @include mobile {
            inset-block-end: 28px;
        }
    }

    .SliderWidget-Figcaption {
        text-align: center;

        @include desktop {
            padding-block-start: 60px;
        }

        @include mobile {
            padding-inline: 16px;
        }
    }
}
