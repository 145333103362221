/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

:root {
    --default-button-font-family: #{$base-font-family};
    --default-button-font-weight: #{$font-weight-medium};
    --default-button-text-transform: uppercase;
    --default-button-font-size: 15px;
    --default-button-letter-spacing: 0.75px;
    --default-text-decoration: none;
    --button-border-width: 1.5px;
    --button-text-transform: uppercase;
    --button-text-align: center;
    --button-height: 40px;
    --button-padding: 0 30px;
    --button-small-height: 25px;
    --button-small-padding: 0 25px;

    // Primary button
    --button-background: var(--primary-base-color);
    --button-border: var(--primary-base-color);
    --button-border-radius: 0;
    --button-color: var(--imported_buttons_color, #{$white});
    --button-text-decoration: none;

    // Primary button (Hover)
    --button-hover-background: var(--secondary-base-color);
    --button-hover-border: var(--secondary-base-color);
    --button-hover-color: var(--color-white);

    // Hollow button (Hollow)
    --hollow-button-background: var(--imported_hollowButtons_background_color, tranparent);
    --hollow-button-border: var(--primary-base-color);
    --hollow-button-color: var(--primary-base-color);
    
    // Hollow button (Hover)
    --hollow-button-hover-background: var(--primary-base-color);
    --hollow-button-hover-border: var(--primary-base-color);
    --hollow-button-hover-color: var(--color-white);

    // Secondary button
    --secondary-button-background: var(--primary-dark-color);
    --secondary-button-border: var(--primary-dark-color);
    --secondary-button-color: var(--color-white);
        
    // Secondary button (Hover)
    --secondary-button-hover-background: #{$grey1};
    --secondary-button-hover-border: #{$grey1};
    --secondary-button-hover-color: var(--color-white);

    // Secondary Revert button
    --secondary-revert-button-background: tranparent;
    --secondary-revert-button-border: var(--secondary-light-color);
    --secondary-revert-button-color: var(--secondary-light-color);
        
    // Secondary Revert button (Hover)
    --secondary-revert-button-hover-background: var(--secondary-light-color);
    --secondary-revert-button-hover-border: var(--secondary-light-color);
    --secondary-revert-button-hover-color: var(--color-white);
}

.Button {
    @include button;
}

button {
    background-color: transparent;
    
    @include desktop {
        transition: $transition1;
    }

    // override default blue color for lists in Safari since iOS15

    & * {
        color: var(--primary-light-color);
    }
}
