/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/mixins';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

.Slider {
    --translateX: 0;
    --translateY: 0;
    --animation-speed: 0;
    --slider-height: 444px;
    --slide-height: 0;
    --slide-width: 0;
    --height-transition-speed: 0;

    @include desktop {
        --slider-height: 444px !important;
    }

    max-width: 100%;
    overflow: hidden;
    touch-action: pan-y;
    transition: height var(--height-transition-speed);

    &-Wrapper {
        display: flex;
        align-items: flex-start;
        height: 100%;
        will-change: transform;
        transition: transform var(--animation-speed);
        transform: translate3d(var(--translateX), var(--translateY), 0);

        > * {
            flex-grow: 1;
            flex-shrink: 0;
            width: 100%;
            height: 100%;
        }

        &_isVertical {
            flex-direction: column;
        }
    }

    &-Image {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &-Crumbs {
        height: auto;
        min-height: 15px;
        position: absolute;
        margin: auto;
        display: grid;
        grid-gap: 15px;
        width: 100%;
        grid-template-columns: repeat(auto-fit, 15px);
        justify-content: center;
        bottom: 0;

        @include mobile {
            min-height: 10px;
            grid-gap: 10px;
            grid-template-columns: repeat(auto-fit, 10px);
        }
    }

    &-Crumb {
        width: 15px;
        height: 15px;
        border-radius: 50%;
        border: 1px solid var(--color-gray4);
        background-color: var(--color-gray4);
        will-change: width, height;
        transition: width 300ms, height 300ms;
        cursor: pointer;

        @include mobile {
            width: 10px;
            height: 10px;
        }

        &:hover {
            @include desktop {
                border-color: var(--primary-base-color);
            }
        }

        &_isActive {
            background-color: var(--primary-base-color);
            border-color: var(--primary-base-color);
        }
    }

    &-Arrows {
        position: absolute;
        top: 44%;
        left: 50%;
        transform: translate(-50%,-50%);
        width: 100%;

        @include mobile {
            display: none;
        }
    }

    &-Arrow {
        cursor: pointer;
        position: absolute;
        inset-block-start: 50%;
        width: 50px;
        height: 50px;
        background: rgba(119, 119, 119, 0.4);

        &:hover {
            @include desktop {
                background: rgba(119, 119, 119, 0.6);
            }
        }

        &_isPrev {
            left: 50px;

            @include arrow-left-icon;

            &::after {
                font-size: 32px;
                line-height: 32px;
                color: var(--color-white);
                font-weight: $font-weight-regular;
            }
        }

        &_isNext {
            right: 50px;

            @include arrow-right-icon;

            &::after {
                font-size: 32px;
                line-height: 32px;
                color: var(--color-white);
                font-weight: $font-weight-regular;
            }
        }

        &_isDisabled {
            pointer-events: none;
        }
    }
}
