/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

.author-of-the-month {
    background: var(--color-gray5);
    padding: 30px 20px;
    margin-bottom: 60px;

    @include mobile {
        min-height: 600px;
    }

    @include desktop {
        padding: 60px 0;
        margin-bottom: 80px;
    }

    @include desktop-xxl {
        min-height: 418px;
    }

    .pagebuilder-column-group {
        display: grid !important;

        @include desktop {
            grid-template-columns: 34% 66%;
            padding: 0 60px;
        }

        .pagebuilder-column {
            width: auto !important;
        }

        h2 {
            text-align: left;
            margin: 30px 0 15px 0;

            @include mobile {
                min-height: 29px;
            }

            @include desktop {
                margin-top: 0;
            }
        }

        figure {
            text-align: center;

            img {
                width: 170px;
                height: 215px;

                @include desktop {
                    width: 220px;
                    height: 279px;
                }
            }
        }

        div[data-content-type='text'] {
            @include mobile {
                min-height: 200px;
            }
        }
    }

    h4 {
        text-align: left;
        margin-bottom: 25px;

        @include mobile {
            min-height: 22px;
        }
    }

    p {
        @include mobile {
            min-height: 196px;
        }
    }

    .find-out-more {
        @include mobile {
            min-height: 22px;
        }

        .pagebuilder-button-link {
            @include button-link();
        }
    }
}
