/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/mixins';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

:root {
    --breadcrumbs-height: 40px;
    --breadcrumbs-active-color: var(--primary-light-color);
    --breadcrumbs-color: var(--primary-light-color);
}

.Breadcrumbs {
    $crumb-padding: 11px;
    $arrow-size: 4px;

    @include mobile {
        --breadcrumbs-background: var(--color-white);

        display: none;
    }

    @include desktop {
        margin-top: -12px;
    }

    &-List {
        list-style: none;
        padding: 30px 0;
        display: flex;
        flex-wrap: wrap;
    }

    &_isMyAccount,
    &_isSharedWishlist {
        background: var(--account-background-color);
    }
}
