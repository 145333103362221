/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

.promotional-banners {
    margin-bottom: 60px;

    @include desktop {
        margin-bottom: 80px;
        min-height: 248px;
    }

    @include desktop-xxl {
        min-height: 325px;
    }

    .pagebuilder-column-group {
        display: grid !important;
        padding: 0 10px;

        @include desktop-xxl {
            padding: 0;
        }

        @include desktop {
            grid-template-columns: repeat(3, 1fr);
            grid-auto-rows: max-content;
            grid-column-gap: 20px;
        }

        .pagebuilder-column {
            margin-bottom: 16px;
            text-align: center;
            width: auto !important;

            @include desktop {
                margin-bottom: 0;
            }

            &:last-child {
                margin-bottom: 0;
            }

            a {
                @include desktop {
                    transition: $transition1;
                    display: flex;

                    &:hover {
                        box-shadow: $box-shadow5;
                    }
                }
            }

            img {
                @include mobile {
                    min-width: 300px;
                    min-height: 232px;
                }

                @include desktop-xxl {
                    width: 420px;
                    height: 325px;
                }
            }
        }
    }
}
