/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/mixins';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

:root {
    --currency-switcher-width: 119px;
    --currency-switcher-height: 88px;
}

.CurrencySwitcher {
    @include desktop {
        padding-left: 15px;
    }

    &-Title {
        @include paragraph-text;
        @include arrow-down-icon;

        line-height: 19px;
        color: var(--color-gray9);
        letter-spacing: $letter-spacing;
        cursor: pointer;
        transition: $transition1;
        display: flex;
        align-items: center;

        @include mobile {
            @include arrow-right-icon;

            justify-content: space-between;
            font-weight: $font-weight-medium;
            padding-block: 20px;
            cursor: pointer;
        }

        &::after {
            @include desktop {
                margin-left: 5px
            }
        }

        &_isActive {
            @include desktop {
                @include arrow-up-icon;
            }
        }
    }

    &-CurrencySwitcherList {
        @include desktop {
            position: absolute;
            opacity: 0;
            visibility: hidden;
            min-height: var(--currency-switcher-height);
        }

        @include mobile {
            opacity: 0;
            pointer-events: none;
            touch-action: pan-y;
            transform: translateX(100%);
            transition: transform .2s ease-in-out;
            height: 100%;
            left: 0;
            padding-top: var(--header-total-height);
            padding-bottom: var(--footer-nav-height);
            position: fixed;
            top: 0;
            width: 100%;
            background: var(--color-white);
            overflow-y: hidden;
        }

        &_isActive {
            @include desktop {
                left: -10px;
                opacity: 1;
                visibility: visible;
                z-index: 1;
                margin-top: 9px;
                min-width: var(--currency-switcher-width);
                box-shadow: $box-shadow2;
            }

            @include desktop-xxl {
                left: -30px;
            }

            @include mobile {
                pointer-events: all;
                z-index: 10;
                opacity: 1;
                transform: translateX(0);
                padding-inline: 0;
                margin-block-end: 0;
                background: var(--color-white);
            }

            &::before {
                @include desktop {
                    left: 35px;
                    content: '';
                    position: absolute;
                    inset-block-start: -6px;
                    height: 10px;
                    width: 10px;
                    transform: rotate(45deg);
                    background-color: var(--overlay-background);
                    margin: auto;
                    box-shadow: $box-shadow2;
                    z-index: 2;
                }
    
                @include desktop-xxl {
                    left: 0;
                    right: 0;
                }
            }
        }

        ul {
            background-color: var(--overlay-background);

            @include mobile {
                padding: 10px;
            }

            @include desktop {
                min-width: var(--currency-switcher-width);
                position: absolute;
                z-index: 3;
            }

            li {
                margin-bottom: 0;
                
                &::before {
                    display: none;
                }
            }
        }
    }

    &-Value {
        @include paragraph-text;

        padding-block-end: 13px;
        padding-block-start: 12px;
        line-height: 19px;
        color: var(--color-gray9);
        letter-spacing: $letter-spacing;
        font-weight: $font-weight-medium;
        cursor: pointer;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        width: 100%;

        @include mobile {
            padding-block-end: 20px;
            padding-block-start: 20px;
            border-bottom: 2px solid var(--primary-divider-color);
        }

        @include desktop {
            padding-left: 20px;
            padding-right: 10px;
        }

        &:hover {
            @include desktop {
                background-color: var(--color-grey11);
            }
        }

        &:last-of-type {
            @include mobile {
                border-bottom: 0;
            }
        }
    }
}
