/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../../src/style/abstract/variables';
@import '../../../../../src/style/abstract/mixins';
@import '../../../../../src/style/abstract/media';
@import '../../../../../src/style/abstract/button';
@import '../../../../../src/style/abstract/loader';
@import '../../../../../src/style/abstract/icons';
@import '../../../../../src/style/abstract/parts';

/**
 * KemanaPWA - Progressive Web App for Magento
 * Copyright © Kemana, Inc. All rights reserved.
 *
 * @package kemana-insta-feed
 * @author ashangunawardena
 */

:root {
    --insta-feed-desktop-width: #{$layout__max-width};
    --insta-feed-image-background: var(--product-image-color);
}

.InstaFeed {
    margin: auto;
    max-width: var(--insta-feed-desktop-width);
    margin-bottom: 80px;

    @include mobile {
        margin-bottom: 60px;
    }

    &-Title {
        text-align: center;

        @include mobile {
            min-height: 29px;
        }

        @include desktop {
            min-height: 39px;
        }
    }

    &-Items {
        display: grid;
        grid-gap: 10px;
        width: 100%;
        grid-template-columns: 1fr 1fr;

        @include mobile {
            padding-left: 10px;
            padding-right: 10px;
            min-height: 308px;
        }

        @include desktop {
            grid-template-columns: repeat(4, 1fr);
            grid-gap: 20px;
            padding-left: 10px;
            padding-right: 10px;
            min-height: 236px;
        }

        @include desktop-xxl {
            padding-left: 0;
            padding-right: 0;
            min-height: 310px;
        }
    }

    &-Item {
        @include mobile {
            min-width: 145px;
            min-height: 149px;
        }

        @include desktop {
            max-width: 310px;
            max-height: 310px;
        }
    }

    &-Permalink {
        @include desktop {
            display: flex;
            flex-wrap: wrap;
        }

        .InstaFeed-ItemOverlay {
            opacity: 0;
            transition: $transition1;

            @include mobile {
                display: none;
            }
        }

        &:hover {
            .InstaFeed-ItemOverlay {
                @include desktop {
                    transition: $transition1;
                    position: absolute;
                    z-index: 1;
                    background: rgba(0, 0, 0, .2);
                    height: 100%;
                    width: 100%;
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;
                    justify-content: center;
                    opacity: 1;
                    cursor: pointer;

                    @include instagram-icon;

                    &::before {
                        color: var(--color-white);
                        font-size: 30px;
                        line-height: 30px;
                    }
                }
            }
        }

        .Image {
            &-Image {
                background-color: var(--insta-feed-image-background);
                object-fit: cover;
                object-position: top;
            }
        }
    }

    &-Placeholder {
        background-image: var(--placeholder-image);
        background-size: var(--placeholder-size);
        animation: var(--placeholder-animation);
        width: 100%;
        height: 100vh;
        
        @include mobile {
            min-height: 180px;
        }

        @include desktop {
            max-height: 310px;
        }
    }
}
