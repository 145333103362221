.home-blog {
    margin-bottom: 80px;

    @include mobile {
        margin-bottom: 60px;
        padding-inline: 10px;
    }

    @include desktop {
        padding-inline: 10px;
        min-height: 453px;
    }

    @include desktop-xxl {
        padding-inline: 0;
    }

    [data-element="main"] {
        > p {
            margin-bottom: 0;
        }
    }

    .post-list-container {
        .row {
            display: grid;
            grid-auto-rows: max-content;
            grid-gap: 20px;
            grid-template-columns: repeat(1, 1fr);

            @include mobile {
                grid-gap: 30px;
            }

            @include desktop {
                grid-template-columns: repeat(3, 1fr);
            }

            .post-list-item {
                margin-left: 0 !important;
            }
        }

        .post-list-item {
            &:hover {
                .post-image {
                    img {
                        @include desktop {
                            transition: $transition1;
                            opacity: 0.56;
                        }
                    }
                }

                .mp-post-title {
                    .post-link-title {
                        @include desktop {
                            text-decoration: underline;
                        }
                    }
                }
            }
        }

        .post-item-wraper {
            .post-image {
                a {
                    display: flex;
                }

                img {
                    width: 100%;
                    object-fit: cover;

                    @include mobile {
                        min-width: 300px;
                        min-height: 220px;
                    }
                }
            }

            .post-info-wraper {
                .mp-post-title {
                    @include h3;

                    margin-top: 20px;
                    margin-bottom: 20px;
                    overflow: hidden;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                    text-overflow: ellipsis;

                    @include mobile {
                        min-height: 54px;
                    }

                    @include desktop {
                        height: 59px;
                    }

                    a {
                        @include h3;

                        &:hover {
                            @include desktop {
                                text-decoration: underline;
                            }
                        }
                    }
                }

                .post-short-description {
                    p {
                        overflow: hidden;
                        display: -webkit-box;
                        -webkit-line-clamp: 3;
                        -webkit-box-orient: vertical;
                        text-overflow: ellipsis;

                        @include desktop {
                            height: 66px;
                        }
                    }
                }

                .mp-post-info {
                    @include sub-heading-1;

                    margin-bottom: 15px;
                    min-height: 19px;

                    .mp-info,
                    .mp-blog-icon {
                        display: none;
                    }
                }
            }

            .actions {
                .mp-read-more {
                    text-transform: uppercase;

                    &:hover {
                        @include desktop {
                            --link-hover: var(--primary-base-color);

                            text-decoration: underline;
                        }
                    }
                }
            }
        }
    }
}
