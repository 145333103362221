/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/mixins';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

:root {
    --color-pagination-link-text: var(--primary-light-color);
    --color-pagination-link-active-text: var(--primary-base-color);
    --pagination-line-height: var(--imported_pagination_line_height, 20px);
    --pagination-font-size: var(--imported_pagination_font_size, 14px);
    --pagination-font-style: var(--imported_pagination_font_style, normal);
}

.PaginationLink {
    border: 1px solid var(--color-grey16);
    color: var(--color-pagination-link-text);
    font-size: var(--pagination-font-size);
    font-style: var(--pagination-font-style);
    line-height: var(--pagination-line-height);
    font-weight: $font-weight-regular;
    letter-spacing: $letter-spacing4;
    width: 35px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 5px;

    &_isArrow {
        margin: 0;
    }

    &:hover {
        @include desktop {
            color: var(--color-pagination-link-active-text);
            text-decoration: none;
            border-color: var(--color-pagination-link-active-text);
        }
    }

    &_isCurrent {
        --color-pagination-link-text: var(--color-pagination-link-active-text);

        border-color: var(--color-pagination-link-active-text);
    }
}
