/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/mixins';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

.HomePage {
    .CmsPage {
        &-Wrapper {
            @include mobile {
                margin-block-start: calc(var(--header-total-height) + 14px);
            }
        }
    }
}

.CmsPage {
    &_isBreadcrumbsHidden {
        margin-block-start: calc(var(--header-total-height) + 12px);

        @include mobile {
            margin-block-start: calc(var(--header-total-height) + 14px);
        }
    }

    &-Content {
        .cms-content {
            @include default-list;
        }
    }

    &-Wrapper {
        grid-template-columns: auto;
        max-width: var(--content-wrapper-width);
        padding-inline: 32px;
        margin-block-start: calc(var(--header-total-height) + 12px);
        margin-block-end: calc(var(--header-nav-height) - 5px);
        margin-inline: auto;

        @include mobile {
            padding-inline: 14px;
            margin-block-start: calc(var(--header-total-height) + 24px);
        }

        @include desktop {
            margin-top: 0;
        }

        &_page_width {
            &_full {
                max-width: 100%;
                padding-inline: 0;
            }
        }
    }

    &-Heading {
        display: none;
    }

    &-SectionPlaceholder {
        background-image: var(--placeholder-image);
        background-size: var(--placeholder-size);
        animation: var(--placeholder-animation);
        margin: 0;
        margin-block-end: 10px;
        padding-block-end: 30%;

        @include mobile {
            padding-block-end: 60%;
        }

        &:first-child {
            padding-block-end: 40%;

            @include mobile {
                padding-block-end: 100%;
            }
        }
    }

    .see-more {
        position: absolute;
        right: 0;
        top: 10px;
        text-transform: uppercase;

        @include mobile {
            right: 10px;
            top: 6px;
        }

        a {
            &:hover {
                @include desktop {
                    --link-hover: var(--link-color);

                    border-bottom: 1px solid var(--link-color);
                }
            }
        }
    }

    .TextPlaceholder {
        margin-block-end: 22px;
        display: block;

        @include mobile {
            margin-block-end: 25px;
        }
    }

    .Slider-Crumbs {
        position: unset;
        margin-block-start: 15px;

        @include mobile {
            margin-block-start: 20px;
        }
    }

    .widget {
        overflow-x: auto;
    }

    img {
        width: 100%;
    }

    [data-content-type='row'] {
        &[data-appearance='contained'] {
            max-width: $layout__max-width;
        }
    }

    .cms-content-wrapper {
        max-width: calc(var(--content-wrapper-width) - 220px);
        margin: 0 auto;
        padding: 0 10px;

        @include desktop {
            padding: 0;
        }

        @include narrow-desktop {
            padding: 0 10px;
        }

        h3 {
            margin-bottom: 20px;

            @include desktop {
                margin-bottom: 15px;
            }
        }
    }

    .image-fullwidth-grid {
        margin: 15px 0 0;
        padding: 0 10px 50px;

        @include desktop {
            padding: 0 0 80px;
            margin: 35px 0 0;
        }

        &:last-of-type {
            padding-bottom: 0;
        }

        img {
            height: 200px !important;
            object-fit: cover;
            object-position: top;

            @include desktop {
                height: 243px !important;
            }
        }

        .pagebuilder-column {
            margin-bottom: 20px;

            @include desktop {
                padding: 0 10px;
                margin-bottom: 0;
            }

            &:first-child {
                padding-left: 0;
            }

            &:last-child {
                padding-right: 0;
                margin-bottom: 0;
            }
        }
    }

    .image-twocolumn-grid {
        margin: 35px 0 50px 0;
        padding: 0 10px;

        @include desktop {
            padding: 0;
        }

        @include desktop {
            margin: 65px 0 80px 0;
        }

        .pagebuilder-column-group {
            .pagebuilder-column {
                min-height: 226px;

                @include desktop {
                    min-height: auto;
                }
            }

            .image-2column-grid-text {
                display: flex;
                background: var(--color-orange4);
                justify-content: center !important;
                order: 2;
                padding: 25px 20px;
                min-height: auto;

                @include desktop {
                    padding: 50px 120px 50px 110px;
                    order: 0;
                    min-height: 450px;
                }

                @include narrow-desktop {
                    padding: 20px;
                }

                h3,
                p {
                    color: var(--color-white);
                }

                h3 {
                    margin-bottom: 20px;

                    @include desktop {
                        margin-bottom: 15px;
                    }
                }
            }
        }

        &.fb-foundation {
            .image-2column-grid-text {
                background: var(--color-gray5);
                color: var(--color-gray1);

                @include desktop {
                    padding: 50px 90px 50px 110px;
                }

                @include narrow-desktop {
                    padding: 20px;
                }

                h3 {
                    color: var(--primary-light-color);
                }

                p {
                    color: var(--color-gray1);
                }

                ul {
                    li {
                        margin-bottom: 10px;
                    }
                }
            }

            .text-devider {
                hr {
                    border: 0;
                    margin: 15px 0;

                    @include desktop {
                        margin: 25px 0;
                    }
                }
            }

            &.card-promo {
                margin-top: 0;

                .image-2column-grid-text {
                    ul {
                        margin-bottom: 30px;
                    }
                }
            }
        }

        &.yellow-text-grid {
            ul {
                li {
                    color: var(--color-white);

                    &::before {
                        background: var(--color-white);
                    }
                }
            }
        }
    }

    .two-column-text-wrap {
        .pagebuilder-column-group {
            .pagebuilder-column {
                &:first-child {
                    margin-bottom: 35px;

                    @include desktop {
                        border-right: 1px solid var(--color-gray2);
                        padding-right: 110px;
                        margin-bottom: 0;
                    }
                }

                &:last-child {
                    @include desktop {
                        padding-left: 110px;
                    }
                }
            }
        }
    }

    .image-fullwidth-grid[data-content-type='row'][data-appearance='full-width'] > {
        .row-full-width-inner {
            max-width: 100%;
        }
    }

    div[data-content-type='text'],
    .careers-grid {
        ul {
            li {
                color: var(--color-gray1);
                margin-bottom: 20px;
                line-height: 22px;

                @include paragraph-text;

                &::before {
                    width: 6px;
                    height: 6px;
                    border-radius: 6px;
                    background: var(--color-orange4);
                    display: inline-block;
                    position: relative;
                    top: 0;
                    margin-right: 10px;
                }
            }
        }
    }

    .careers-grid {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 20px;

        h6 {
            margin-bottom: 20px;
        }
    }

    .card-promo-ul {
        p {
            padding-left: 15px;
            margin-bottom: 20px;
        }

        h4 {
            display: inline-block;
        }

        div[data-content-type='text'] {
            &:last-child {
                margin-top: 15px;

                p {
                    padding-left: 0;
                }
            }

            ul {
                li {
                    margin-bottom: 10px;

                    &::before {
                        top: -4px;
                    }
                }
            }
        }
    }

    .card-promo-info-text {
        margin-bottom: 30px;

        h3 {
            margin-bottom: 20px;
        }

        ul {
            margin-top: 5px;
        }

        div[data-content-type='text'] {
            &:nth-child(2) {
                margin-bottom: 15px;
            }
        }
    }

    .yellow-grid-images {
        background: var(--color-orange4);
        padding: 25px 10px;
        margin-bottom: 50px;

        @include desktop {
            padding: 40px 0;
            margin-bottom: 80px;
        }

        @include narrow-desktop {
            padding: 25px 10px;
        }

        .pagebuilder-column-group {
            max-width: calc(var(--content-wrapper-width) - 220px);
            margin: 0 auto;

            .pagebuilder-column {
                &:first-child {
                    margin-bottom: 20px;

                    @include desktop {
                        margin-right: 9px;
                        margin-bottom: 0;
                    }
                }

                &:last-child {
                    @include desktop {
                        margin-left: 9px;
                    }
                }
            }
        }
    }

    .site-faq-accodian {
        margin-bottom: 50px;

        h3 {
            margin-bottom: 20px;
        }

        div[data-content-type='html'] {
            border: 1px solid var(--color-gray4);

            details {
                padding: 0 15px 0 10px;
                border-bottom: 1px solid var(--color-gray4);

                @include desktop {
                    padding: 0 20px;
                }

                summary {
                    @include h6;

                    padding: 20px 20px 20px 0;
                    cursor: pointer;

                    @include faq-plus-thin-icon;

                    &::after {
                        position: absolute;
                        right: 0;
                        top: 20px;
                    }
                }

                &:last-child {
                    border-bottom: 0;
                }
            }

            details[open] {
                summary {
                    border-bottom: 1px solid var(--color-gray4);
                    margin-bottom: 20px;

                    @include faq-minus-thin-icon;

                    &::after {
                        right: 0;
                    }
                }
            }
        }
    }

    .card-promo-single-image {
        img {
            max-width: 859px !important;
            margin: 0 auto;
        }
    }

    .cms-content-base {
        h3 {
            margin-top: 35px;
        }
    }

    .sub-ul-list {
        margin-left: 20px;
    }

    .faq-page {
        div[data-content-type='html'] {
            margin-bottom: 50px;

            &:last-of-type {
                margin-bottom: 0;
            }
        }

        p {
            margin-bottom: 30px;
        }
    }

    .authors-twocolumn-grid {
        margin: 0 10px 50px 10px;

        @include desktop-xxl {
            margin-left: 0;
            margin-right: 0;
        }

        img {
            object-fit: cover;
        }

        @include desktop {
            margin-bottom: 80px;
            padding: 0;
        }

        .authors-grid-text {
            background: var(--secondary-base-color);
            padding: 25px 20px;

            h2,
            p {
                color: var(--color-white);
            }

            h2 {
                padding: 0;
            }

            @include desktop {
                padding: 50px 80px;
            }
        }

        &.gray-bg {
            border-top: 1px solid var(--secondary-divider-color);
            padding-top: 50px;

            @include desktop {
                margin-top: 50px;
                padding-top: 80px;
            }

            .authors-grid-text {
                background: var(--color-gray5);

                h2 {
                    color: var(--primary-light-color);
                }

                p {
                    color: var(--color-gray1);
                }
            }
        }

        .pagebuilder-column {
            &:first-child {
                figure {
                    height: 100%;

                    img {
                        @include mobile {
                            object-position: top;
                            margin-bottom: -4px;
                        }

                        @include desktop {
                            height: 100% !important;
                        }
                    }
                }

                @include desktop {
                    max-width: 390px;
                }
            }

            &:last-child {
                @include desktop {
                    max-width: calc(100% - 390px);
                    width: 100% !important;
                    justify-content: center !important;
                }
            }
        }
    }

    .authors-products {
        margin-bottom: 50px;

        @include desktop {
            margin-bottom: 50px;
        }
    }

    .author-title {
        padding: 0 10px;

        @include desktop {
            padding: 0;
        }
    }
}
