/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../../src/style/abstract/variables';
@import '../../../../../src/style/abstract/mixins';
@import '../../../../../src/style/abstract/media';
@import '../../../../../src/style/abstract/button';
@import '../../../../../src/style/abstract/loader';
@import '../../../../../src/style/abstract/icons';
@import '../../../../../src/style/abstract/parts';

/**
 * KemanaPWA - Progressive Web App for Magento
 * Copyright © Kemana, Inc. All rights reserved.
 *
 * @package kemana-free-gift
 * @author ashangunawardena
 */

:root {
    --free-gift-item-background: var(--color-gray5);
    --free-gift-popup-desktop-width: 940px;
}

.FreeGiftPopup {
    &-Notice {
        p {
            text-align: center;
            margin-bottom: 0;
        }
    }

    .Popup {
        &-Heading {
            @include desktop {
                text-align: center;
                margin-bottom: 20px;

                @include h3;
            }
        }

        &-CloseBtn {
            right: -20px;
        }

        &-Content {
            @include desktop {
                --popup-max-height: 558px;
                --popup-content-padding: 20px 40px;
        
                max-width: var(--free-gift-popup-desktop-width);
            }

            @include mobile {
                --popup-content-padding: 20px 10px;
            }

            .GiftWrapperPopup {
                &-ItemTitle {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                    margin-bottom: 10px;
                    height: 38px;
                }

                &-ItemPrice {
                    margin-bottom: 10px;
                    display: none;
                }

                &-AvailableQty {
                    font-size: 12px;
                    margin-bottom: 5px;
                }

                &-List {
                    @include mobile {
                        margin-top: 20px;
                    }

                    &Item {
                        background: var(--free-gift-item-background);
                        padding: 10px;
    
                        &_isActive,
                        &:hover {
                            .Image-Image {
                                @include desktop {
                                    transition: all .5s;
                                    opacity: .6;
                                }
                            }
                        }
    
                        &:hover {
                            .GiftWrapperPopup-ItemTitle {
                                @include desktop {
                                    text-decoration: underline;
                                }
                            }
                        }

                        .GiftWrapperPopup-FreeGiftCheckbox {
                            position: absolute;
                            height: calc(100% - 55px);
                            width: 100%;
                            z-index: 1;
                            left: 0;
                            top: 0;

                            .MyAccountOverlay {
                                &-Checkbox {
                                    margin: 0;
                                    height: 100%;
                                }
                            }

                            .Field {
                                &-CheckboxLabel {
                                    padding-bottom: 0;
                                    max-width: 100%;
                                    width: 100%;
                                    height: 100%;
                                    display: inline-block;
                                    text-align: right;
                                }

                                &-Wrapper_type_checkbox {
                                    &:first-of-type {
                                        margin-top: 0;
                                        height: 100%;
                                    }
                                }

                                [type='checkbox'] {
                                    left: 0;
                                    
                                    + .input-control {
                                        margin-right: 0;
                                        margin-right: 10px;
                                        margin-top: 10px;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .CartItem {
                &-Qty {
                    @include qty-selector;

                    button {
                        &:first-of-type {
                            &::after {
                                margin-top: 1px;
                            }
                        }
                    }
                }
            }
        }
    }

    .GiftWrapperPopup-ListItem_isActive {
        .GiftWrapperPopup-ItemTitle {
            color: var(--primary-light-color);
        }
    }

    &-Buttons {
        text-align: center;
    }

    &-AddtoCartButton {
        margin-top: 30px;

        @include desktop {
            margin-top: 40px;
            margin-bottom: 20px;
        }
    }
}
