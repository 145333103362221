/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/mixins';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

:root {
    --install-prompt-ios-background: var(--color-grey19);
}

.InstallPromptIOS {
    background: var( --install-prompt-ios-background);
    padding: 8px 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &-Left {
        width: calc(100% - 36px);
    }

    &-Heading {
        text-align: left;
        width: 100%;
        line-height: 19px;
    }

    &-Content {
        align-items: center;
        display: flex;
        justify-content: left;
        width: 100%;
        font-size: 12px;
        line-height: 15px;
        color: var(--secondary-light-color);
        margin: 0;
    }

    &-Close {
        width: 20px;
        height: 20px;
        margin-right: 15px;

        &::before {
            color: var(--color-white);
        }

        span {
            display: none;
        }
    }

    &-Plus {
        @include qty-plus-thin-icon;

        background-color: var(--secondary-light-color);
        width: 15px;
        height: 15px;
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 5px;

        &::after {
            font-size: 10px;
            line-height: 16px;
            color: var(--color-white);
            margin-top: 1.5px;
            margin-left: .5px;
        }
    }

    &-Share {
        @include ios-share-icon;

        margin: 0 0 0 5px;
    }

    p {
        line-height: 1;
    }
}
