/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

%loader {
    position: relative;
    width: 40px;
    box-sizing: border-box;
    transform-origin: 50% 50%;

    &::before,
    &::after {
        content: '';
        position: absolute;
        z-index: 1;
        width: 28px;
        height: 28px;
        transform: scaleY(.5774) rotate(-45deg);
        background-color: inherit;
        left: 1px;
        box-sizing: border-box;
        display: none;
    }

    // ltr + rtl adjusted, don't replace 'left' and 'right' here

    &::before {
        top: -14px;
        border-top: solid 7px var(--primary-base-color);
        border-right: solid 7px var(--primary-base-color);
    }

    &::after {
        bottom: -14px;
        border-bottom: solid 7px var(--primary-base-color);
        border-left: solid 7px var(--primary-base-color);
    }

    img {
        border-radius: 50%;
    }

    span {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        width: 18px;
        height: 18px;
        background-color: rgb(80, 88, 101);
        border-radius: 50%;
        z-index: 2;
    }
}
