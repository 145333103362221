/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/mixins';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

:root {
    --install-prompt-android-background: var(--color-grey19);
}

.InstallPromptAndroid {
    --button-background: var(--secondary-button-background);
    --button-color: var(--secondary-button-color);
    --button-border: transparent;
    --button-hover-background: var(--secondary-button-background);
    --button-hover-color: var(--secondary-button-color);
    --button-hover-border: transparent;
    --button-padding: 40px;
    --button-border-width: 0;

    background: var(--install-prompt-android-background);
    padding: 9px 10px 9px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &-Left {
        width: calc(100% - 132px);
    }

    &-Heading {
        text-align: left;
        width: 100%;
        line-height: 19px;
    }

    &-Content {
        line-height: 15px;
        color: var(--secondary-light-color);
        align-items: center;
        display: flex;
        justify-content: left;
        width: 100%;
        font-size: 12px;
        letter-spacing: $letter-spacing1;
        margin: 0;
    }

    &-Close {
        width: 20px;
        height: 20px;
        margin-right: 15px;
    }

    &-Button {
        --button-height: 27px;
        --button-padding: 0 16px;
        --default-button-font-size: 12px;
        --default-button-letter-spacing: #{$letter-spacing3};

        width: max-content;

        &.Button {
            @include mobile {
                width: max-content;
            }
        }
    }
}
