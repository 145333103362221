/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/mixins';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

:root {
    --header-logo-width: 237px;
    --header-logo-height: 34px;

    --header-height: 55px;
    --header-nav-height: 75px;
    --header-total-height: calc(var(--header-height) + var(--offline-notice-height) + var(--demo-notice-height) + env(safe-area-inset-top));

    --header-items-hover-background-color: var(--color-orange3);
    --header-items-hover-color: var(--color-white);

    --header-checkout-steps-active-color: var(--color-yellow3);

    @include mobile {
        --header-nav-height: 55px;
    }

    @include desktop {
        --header-top-menu-height: 45px;
        --header-height: calc(var(--header-nav-height) + var(--header-top-menu-height) + var(--menu-total-height));
    }
}

@mixin button-invisible {
    margin: 0;
    opacity: 0;
    overflow: hidden;
    padding: 0;
    pointer-events: none;
    width: 0;
    height: 0;
}

@mixin button-visible {
    opacity: 1;
    height: 25px;
    width: 25px;
    pointer-events: all;
    overflow: visible;
    cursor: pointer;
}

@mixin visible-on-mobile {
    @include button-visible;

    @include desktop {
        @include button-invisible;
    }
}

@mixin logo-visible {
    opacity: 1;
    max-width: 100%;
}

.Header {
    align-items: center;
    background: var(--body-background-color);
    height: fit-content;
    display: flex;
    flex-direction: column;
    padding-block-start: env(safe-area-inset-top, 0);
    width: 100%;
    position: fixed;
    z-index: 100;
    inset-block-start: var(--demo-notice-height);

    > * {
        width: 100%;
    }

    &_menu,
    &_menu_subcategory {
        .Header-Button_type {
            &_close {
                @include desktop {
                    @include button-visible;
                }
            }

            &_menu {
                @include desktop {
                    @include button-invisible;
                }
            }
        }
    }

    &_name {
        &_menu {
            border-block-end: none;
        }

        &_search,
        &_menu_subcategory {
            border-block-end: 0;
        }

        &_category {
            position: fixed;

            @include mobile {
                border-block-end: none;
            }
        }
    }

    &-Button {
        @include button-invisible;

        &_isVisible {
            @include mobile {
                @include button-visible;
            }
        }

        &_isDisabled {
            pointer-events: none;
            opacity: .1;
        }

        &_type {
            &_menu,
            &_minicart {
                @include button-visible;

                @include desktop {
                    height: 24px;
                    margin-inline-start: 35px;
                }
            }

            &_account {
                @include button-visible;
            }

            &_home {
                @include visible-on-mobile;
            }

            &_minicart {
                @include desktop {
                    margin-inline-start: 35px;
                }
            }

            &_back {
                @include back-icon;
            }

            &_close {
                .CloseIcon {
                    width: 25px;
                    height: 18px;

                    &::before {
                        font-size: 15px;
                        line-height: 19px;
                        font-weight: $font-weight-bold;
                    }
                }
            }

            &_share {
                @include button-visible;
                @include share-icon;

                &::before {
                    color: var(--primary-base-color);
                }

                svg {
                    @include mobile {
                        display: none
                    }
                }

                &:disabled {
                    pointer-events: none;

                    &::before {
                        color: var(--color-gray7);
                    }
                }
            }

            &_compare {
                @include button-visible;
            }
        }

        &[aria-hidden="true"] {
            display: none;
        }
    }

    &-MinicartButtonWrapper {
        padding: 0 20px;
        height: var(--header-nav-height);
        display: flex;
        align-items: center;
        cursor: pointer;
        transition: $transition1;
        font-size: 15px;
        line-height: 19px;
        font-weight: $font-weight-regular;
        letter-spacing: $letter-spacing;

        @include minicart-icon;

        &::before {
            margin-right: 10px;
        }

        &:hover {
            background-color: var(--header-items-hover-background-color);

            &::before {
                color: var(--header-items-hover-color);
            }

            .Header-MinicartTitle {
                color: var(--header-items-hover-color);
            }

            .Header-MinicartItemCount {
                background-color: var(--header-items-hover-color);
                color: var(--primary-base-color);

                span {
                    color: var(--primary-base-color);
                }
            }
        }
    }

    &-MinicartTitle {
        color: var(--color-gray9);
    }

    &-CompareButtonWrapper {
        margin-inline-start: 35px;
        height: 24px;

        a {
            all: initial;
        }
    }

    &-CompareCount {
        background: #0A0903;
        border-radius: 50%;
        color: var(--color-white);
        height: 17px;
        padding: 2px;
        position: absolute;
        width: 17px;
        display: flex;
        font-size: 11px;
        justify-content: center;
        align-items: center;
        font-weight: $font-weight-bold;
        font-family: "Muli", sans-serif;

        @include mobile {
            inset-block-start: -7px;
            inset-inline-end: -11px;
        }

        @include desktop {
            inset-block-start: -10px;
            inset-inline-end: -12px;
        }
    }

    &-LogoWrapper {
        inset-block-end: 0;
        height: var(--header-logo-height);
        inset-inline-start: 0;
        max-width: 0;
        opacity: 0;
        overflow: hidden;
        inset-inline-end: 0;
        inset-block-start: 0;
        width: var(--header-logo-width);

        @include mobile {
            --header-logo-width: 196px;
            --header-logo-height: 29px;

            margin: auto;
        }

        @include desktop {
            --header-logo-width: 237px;
            --header-logo-height: 34px;

            @include logo-visible;
        }

        &_isVisible {
            @include logo-visible;
        }

        .Image-Image {
            @include desktop {
                object-fit: cover;
            }
        }
    }

    &-Minicart {
        @include print-only {
            display: none;
        }

        &ItemCount {
            background: var(--primary-base-color);
            border-radius: 7px;
            color: var(--color-white);
            height: 16px;
            padding: 2px;
            position: absolute;
            width: 16px;
            display: flex;
            font-size: 9px;
            justify-content: center;
            align-items: center;
            font-family: $font-source-sans-pro;
            font-weight: $font-weight-regular;

            span {
                color: var(--color-white);
                margin-left: 1px;
                line-height: 16px;
            }

            @include mobile {
                inset-block-start: -7px;
                inset-inline-end: -11px;
            }

            @include desktop {
                top: 20px;
                left: 30px;
            }
        }
    }

    &-Nav {
        align-items: center;
        display: flex;
        height: var(--header-nav-height);
        padding-left: 10px;
        padding-right: 10px;

        @include mobile {
            box-shadow: $box-shadow2;
            flex-wrap: wrap;
        }

        @include desktop {
            justify-content: unset;
            padding-left: 32px;
            padding-right: 32px;
            transform: translateY(45px);
            transition: $transition2;
        }
    }

    &-News {
        align-items: center;
        display: flex;

        &Button {
            font-size: 12px;
            margin-inline-start: 5px;
        }

        .ExclamationMarkIcon {
            inset-block-end: 1px;
            height: 16px;
            margin-inline-end: 10px;
            width: 16px;
        }
    }

    &-Title {
        color: var(--header-color);
        margin: auto;
        opacity: 0;
        overflow: hidden;
        pointer-events: none;
        position: absolute;
        inset-inline-end: 0;
        text-align: center;
        text-overflow: ellipsis;
        inset-block-start: -1px;
        inset-inline-start: 0;
        inset-block-end: 0;
        transition-duration: 200ms;
        transition-property: opacity;
        white-space: nowrap;
        width: 100%;
        text-transform: inherit;
        height: 20px;

        @include h6;

        &_isVisible {
            @include mobile {
                opacity: 1;
                max-width: calc(100% - 90px);
                position: absolute;
                left: 0;
                right: 0;
                text-transform: uppercase;
            }
        }
    }

    &-Welcome {
        display: none;
        font-size: 15px;
        text-align: end;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        max-width: 200px;
        align-self: center;
        line-height: 19px;
        letter-spacing: $letter-spacing;

        @include desktop {
            display: block;
            color: var(--color-gray9);
        }
    }

    &-TopMenu,
    &-Nav {
        display: flex;
        margin: auto;
        max-width: var(--layout-max-width);

        @include desktop {
            padding-left: 10px;
            padding-right: 10px;
        }

        @include desktop-xxl {
            padding-left: 0;
            padding-right: 0;
        }

        @include print-only {
            display: none;
        }
    }

    &-Promo {
        p {
            color: var(--color-gray9);
            letter-spacing: 0.3px;
            line-height: 19px;
            margin: 0;

            strong {
                font-weight: $font-weight-semibold;
            }
        }
    }

    &-TopMenu {
        flex-wrap: wrap;
        height: var(--header-top-menu-height);
        justify-content: space-between;
        padding-block-start: 13px;
        padding-block-end: 13px;
        border-bottom: 1px solid var(--color-grey10);
        transform: translateY(0%);

        @include desktop {
            transition: $transition2;
            position: absolute;
            width: 100%;
            z-index: 2;
        }

        @include mobile {
            display: none;
        }

        @include print-only {
            display: none;
        }
    }

    &-TopMenuLeft {
        @include desktop {
            width: 35%;
        }

        @include desktop-xxl {
            width: 40%;
        }
    }

    &-TopMenuRight {
        @include mobile {
            display: none;
        }

        @include desktop {
            width: 65%;
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-end;
        }

        @include desktop-xxl {
            width: 60%;
        }
    }

    &-TopLinks {
        ul {
            li {
                &:last-of-type {
                    margin-bottom: 0;
                }

                &::before {
                    display: none;
                }
            }
        }

        .Button {
            --button-color: var(--color-gray9);
            --button-hover-color: var(--color-gray9);
            --default-button-font-weight: $font-weight-regular;

            @include desktop {
                border-right: 1px solid var(--color-gray3);
                padding: 0 7px;
                line-height: 19px;
            }

            @include desktop-xxl {
                padding: 0 15px;
            }
        }
    }

    &-Switcher {
        display: flex;
        flex-wrap: wrap;
    }

    &_isCheckout {
        .Header {
            &-Button_type_account {
                display: block;
            }

            &-Button_type_close,
            &-Button_type_back {
                @include desktop {
                    display: none;
                }
            }

            &-MyAccount {
                @include mobile {
                    position: absolute;
                    inset-inline-end: 10px;
                    width: auto;
                }

                .MyAccountOverlay {
                    &-Additional_state_signIn {
                        display: none;
                    }
                }
            }

            &-CheckoutStep {
                display: flex;
                flex-wrap: wrap;
                top: 50%;
                left: 50%;
                transform: translate(-50%,-50%);
                position: absolute;

                @include mobile {
                    display: none;
                }

                li {
                    width: 130px;
                    text-align: center;
                    margin-bottom: 0;

                    &::before {
                        width: 100%;
                        width: 28px;
                        height: 28px;
                        border: 1px solid var(--secondary-divider-color);
                        position: static;
                        display: inline-block;
                        border-radius: 50%;
                        margin-bottom: 5px;
                    }

                    span {
                        display: inline-block;
                        width: 100%;
                        font-size: 12px;
                        line-height: 15px;
                        letter-spacing: $letter-spacing;
                        color: var(--secondary-divider-color);
                        text-transform: uppercase;
                    }

                    &:first-of-type {
                        &::after {
                            background: var(--primary-divider-color);
                            top: 15px;
                            content: '';
                            width: 78px;
                            height: 1px;
                            right: -30%;
                            position: absolute;
                        }
                    }

                    &.Header-ShippingStep {
                        @include shipping-step-icon;

                        &::before {
                            line-height: 28px;
                        }

                        &_isActive {
                            &::before {
                                border-color: var(--header-checkout-steps-active-color);
                                background: var(--header-checkout-steps-active-color);
                                color: var(--color-white);
                            }

                            span {
                                color: var(--header-checkout-steps-active-color);
                            }
                        }
                    }

                    &.Header-BillingStep {
                        @include billing-step-icon;

                        &::before {
                            line-height: 28px;
                            margin-left: 1px;
                        }

                        &_isActive {
                            &::before {
                                border-color: var(--header-checkout-steps-active-color);
                                background: var(--header-checkout-steps-active-color);
                                color: var(--color-white);
                            }

                            span {
                                color: var(--header-checkout-steps-active-color);
                            }
                        }
                    }
                }
            }
        }

        .MyAccountOverlay {
            @include mobile {
                inset-block-start: var(--header-total-height);
                height: calc(100vh - var(--header-total-height));
                position: fixed;
                width: 100vw;
            }
        }
    }

    &-Wrapper {
        display: flex;
        justify-content: center;
        margin-block-end: var(--header-total-height);

        @include print-only {
            display: none;
        }

        &_isHome {
            @include mobile {
                --header-total-height: 150px;

                .Header {
                    .Header-TopMenu {
                        @include mobile {
                            position: absolute;
                            top: 0;
                            background-color: var(--body-background-color);
                            transition: transform .2s ease-in-out;
                            z-index: 1;
                            display: flex;
                            flex-wrap: wrap;
                            width: 100%;
                            padding-top: 0;
                            padding-bottom: 0;
                            padding-left: 10px;
                            padding-right: 10px;
                            border-bottom: 0;
                            min-height: 31px;

                            .Header-TopMenuLeft {
                                width: 100%;
                                padding-top: 7px;
                                padding-bottom: 7px;
                                border-bottom: 2px solid var(--color-grey10);
                                text-align: center;
                            }

                            .Header-Promo {
                                p {
                                    font-size: 12px;
                                    letter-spacing: 0.24px;
                                    line-height: 15px;
                                }
                            }
                        }
                    }

                    &-Nav {
                        --header-nav-height: 120px;

                        transform: translateY(30px);
                        transition: transform .2s ease-in-out;
                        background-color: var(--color-white);
                        align-items: flex-start;

                        .Header-LogoWrapper {
                            @include logo-visible;

                            height: 50px;
                        }

                        .SearchField {
                            border-top: 1px solid var(--color-grey10);
                            padding-top: 10px
                        }
                    }
                }
            }
        }

        &_isPrerendered {
            @include mobile {
                .Header-TopMenu,
                .Menu-MenuWrapper {
                    display: none;
                }

                .Header-Nav {
                    & > * {
                        display: none;
                    }

                    .Header {
                        &-Title,
                        &-LogoWrapper,
                        &-Button {
                            &_isVisible {
                                display: block;
                            }
                        }
                    }
                }
            }
        }

        &_isCheckout {
            margin-bottom: var(--header-nav-height);

            .Header_isCheckout {
                .Header {
                    &-TopMenu {
                        display: none;
                    }

                    &-Nav {
                        @include desktop {
                            --layout-max-width: 1100px;

                            justify-content: space-between;
                            transform: translateY(0);
                            transition: none;
                        }
                    }

                    &-WishList {
                        display: none;
                    }
                }
            }
        }

        &_name_checkout_callback {
            margin-bottom: var(--header-nav-height);

            .Header_name_checkout_callback {
                .Header {
                    &-TopMenu,
                    &-Minicart,
                    &-WishList,
                    &-MyAccountContainer {
                        display: none;
                    }

                    &-Nav {
                        @include desktop {
                            --layout-max-width: 1100px;

                            justify-content: space-between;
                            transform: translateY(0);
                            transition: none;
                        }

                        .SearchField {
                            display: none;
                        }
                    }
                }

                .Menu-MenuWrapper {
                    display: none;
                }
            }
        }

        &_isDetailsStep {
            .Header_isCheckout {
                .Header-MyAccountContainer {
                    display: none;
                }
            }
        }
    }

    &_name_popup {
        z-index: 400;
    }

    &-MyAccountContainer {
        display: flex;

        @include print-only {
            display: none;
        }

        .MyAccountOverlay-Additional {
            &_state_forgotPassword {
                section {
                    &:last-of-type {
                        padding-top: 0;
                    }
                }

                &Success {
                    margin-top: 0;
                    border-top: 0;
                }
            }
        }
    }

    &-MyAccountWrapper {
        padding: 0 20px;
        height: var(--header-nav-height);
        display: flex;
        align-items: center;
        cursor: pointer;

        @include account-icon;

        &::before {
            margin-right: 10px;
        }

        &:hover {
            background-color: var(--header-items-hover-background-color);

            &::before {
                color: var(--header-items-hover-color);
            }

            .Header-Welcome {
                color: var(--header-items-hover-color);
            }
        }
    }

    &-WishList {
        cursor: pointer;
        transition: $transition1;

        @include print-only {
            display: none;
        }

        &Link {
            --link-color: var(--color-gray9);
            --link-hover: var(--color-white);

            display: flex;
            align-items: center;
            font-size: 15px;
            line-height: 19px;
            font-weight: $font-weight-regular;
            letter-spacing: $letter-spacing;
            padding: 0 20px;
            height: var(--header-nav-height);

            @include wishlist-icon;

            &::before {
                margin-right: 10px;
            }

            &:hover {
                --link-color: var(--color-white);
                --link-hover: var(--color-white);

                background-color: var(--header-items-hover-background-color);

                &::before {
                    color: var(--header-items-hover-color);
                }
            }
        }
    }
}
