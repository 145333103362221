/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../../src/style/abstract/variables';
@import '../../../../../src/style/abstract/mixins';
@import '../../../../../src/style/abstract/media';
@import '../../../../../src/style/abstract/button';
@import '../../../../../src/style/abstract/loader';
@import '../../../../../src/style/abstract/icons';
@import '../../../../../src/style/abstract/parts';

/**
 * KemanaPWA - Progressive Web App for Magento
 * Copyright © Kemana, Inc. All rights reserved.
 *
 * @package kemana-blog
 * @author ashangunawardena
 */

 .BlogDateSorts {
    border-top: 1px solid var(--color-grey16);
    margin-top: 25px;
    padding-top: 25px;

    &-List {
        display: grid;

        &Items {
            @include date-icon;

            margin-bottom: 20px;

            &:before {
                margin-right: 10px;
            }

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    &-ListItem {
        font-weight: $font-weight-regular;
        color: var(--primary-light-color);
        font-size: 14px;
        line-height: 19px;
        letter-spacing: $letter-spacing1;

        span {
            color: var(--color-gray2);
        }

        &:hover {
            @include desktop {
                text-decoration: underline;
            }
        }
    }
}
