/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../../src/style/abstract/variables';
@import '../../../../../src/style/abstract/mixins';
@import '../../../../../src/style/abstract/media';
@import '../../../../../src/style/abstract/button';
@import '../../../../../src/style/abstract/loader';
@import '../../../../../src/style/abstract/icons';
@import '../../../../../src/style/abstract/parts';

/**
 * KemanaPWA - Progressive Web App for Magento
 * Copyright © Kemana, Inc. All rights reserved.
 *
 * @package kemana-password-field
 * @author ashangunawardena
 */

.FieldInput-Password {
    .FieldInput {
        &-TogglePassword {
            position: absolute;
            display: block;
            right: 15px;
            top: 10px;
            width: 20px;
            height: 22px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 0;
            background: transparent;
            border: 0;
            padding: 0;
            cursor: pointer;
    
            @include password-show-icon;
    
            &:not([disabled]) {
                &:hover {
                    background: transparent;
                }
            }
        }
    }

    &_isTogglePasswordActive {
        .FieldInput-TogglePassword {
            @include password-hide-icon;
        }
    }
}
