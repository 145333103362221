/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/mixins';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

:root {
    --search-item-divider-color: #eee;
    --search-item-title-color: #000;
}

.SearchItem {
    margin-bottom: 0;

    &:last-child {
        margin-bottom: 0;
    }

    &::before {
        content: none;
    }

    &-Link {
        padding-block-end: 6px;
        padding-block-start: 7px;
        padding-left: 20px;
        padding-right: 20px;
        display: flex;
        flex-wrap: wrap;
        width: 100%;

        @include mobile {
            padding-block-end: 10px;
            padding-block-start: 10px;
            padding-left: 10px;
            padding-right: 10px;
        }

        &:hover {
            @include desktop {
                background-color: var(--color-gray4);
            }
        }
    }

    &-CustomAttribute {
        color: var(--color-black);
        font-size: 12px;
        font-weight: 400;
        opacity: .5;
    }

    &-Wrapper {
        width: 100%;
    }

    &-Title {
        font-size: 15px;
        line-height: 22px;
        letter-spacing: $letter-spacing1;
    }

    &-NonHighlighted {
        color: var(--color-gray2);
    }

    &-Highlighted {
        color: var(--primary-light-color);
        font-weight: $font-weight-bold;
    }
}
