/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/mixins';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.HomePage {
    .CmsPage {
        &,
        &-Wrapper {
            margin-block-end: 0;
        }
    }

    &-RecentlyViewed {
        .CmsBlock {
            &-Wrapper {
                max-width: $layout__max-width;
                margin-left: auto;
                margin-right: auto;

                [data-element="main"] {
                    > p {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }

    main {
        @include desktop {
            margin-block-start: -12px;
        }
    }
}
