/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../../src/style/abstract/variables';
@import '../../../../../src/style/abstract/mixins';
@import '../../../../../src/style/abstract/media';
@import '../../../../../src/style/abstract/button';
@import '../../../../../src/style/abstract/loader';
@import '../../../../../src/style/abstract/icons';
@import '../../../../../src/style/abstract/parts';

/**
 * KemanaPWA - Progressive Web App for Magento
 * Copyright © Kemana, Inc. All rights reserved.
 *
 * @package kemana-discount-card
 * @author ashangunawardena
 */

:root {
    --my-account-discount-card-background: var(--color-white);
    --my-account-discount-card-desktop-width: 340px;
}

.DiscountCard {
    background: var(--my-account-discount-card-background);
    padding: 20px 10px;

    @include desktop {
        padding: 20px;
        border-radius: 5px;
    }

    &-Heading {
        @include mobile {
            display: none;
        }
    }

    &-CardInformation {
        width: 100%;
        display: flex;
        align-items: flex-end;
        flex-wrap: wrap;
        border-radius: 6px;
        margin-bottom: 30px;

        @include desktop {
            max-width: var(--my-account-discount-card-desktop-width);
        }

        &Container {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            position: absolute;
            padding-left: 25px;
        }

        &Title {
            width: 100%;
            font-size: 11px;
            line-height: 10px;
            padding-bottom: 10px;
            margin-bottom: 10px;
            border-bottom: 1px solid var(--color-white);
            color: var(--color-white);
        }

        &Number {
            font-size: 24px;
            font-weight: $font-weight-semibold;
            color: var(--color-white);
            padding-bottom: 18px;
        }
    }

    &-Description {
        color: var(--primary-light-color);
        letter-spacing: $letter-spacing9;
        margin-bottom: 30px;

        @include desktop {
            max-width: 490px;
        }
    }

    &-ValidateCardDetails {
        .Field-Wrapper {
            &:first-of-type {
                margin-top: 0;
            }

            textarea {
                width: 100%;

                @include desktop {
                    max-width: 440px;
                    height: 40px;
                }
            }
        }
    }

    &-Buttons {
        margin-top: 30px;
    }
}
