/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

:root {
    // h1 desktop
    --h1-font-size: var(--imported_h1_font_size, 36px);
    --h1-font-weight: var(--imported_h1_font_weight, 700);
    --h1-color: var(--imported_h1_color, #{$black});
    --h1-line-height: var(--imported_h1_line_height, 40px);
    --h1-text-transform: var(--imported_h1_text_transform, capitalize);
    --h1-font-style: var(--imported_h1_font_style, normal);

    // h1 mobile
    --h1-font-weight-mobile: var(--imported_h1Mobile_font_weight, 700);
    --h1-font-size-mobile: var(--imported_h1Mobile_font_size, 24px);
    --h1-color-mobile: var(--imported_h1Mobile_color, #{$black});
    --h1-line-height-mobile: var(--imported_h1Mobile_line_height, 28px);
    --h1-text-transform-mobile: var(--imported_h1Mobile_text_transform, capitalize);
    --h1-font-style-mobile: var(--imported_h1Mobile_font_style, normal);

    // h2 desktop
    --h2-font-size: var(--imported_h2_font_size, 24px);
    --h2-font-weight: var(--imported_h2_font_weight, 700);
    --h2-color: var(--imported_h2_color, #{$black});
    --h2-line-height: var(--imported_h2_line_height, 28px);
    --h2-text-transform: var(--imported_h2_text_transform, capitalize);
    --h2-font-style: var(--imported_h2_font_style, normal);

    // h2 mobile
    --h2-font-size-mobile: var(--imported_h2Mobile_font_size, 18px);
    --h2-font-weight-mobile: var(--imported_h2Mobile_font_weight, 700);
    --h2-color-mobile: var(--imported_h2Mobile_color, #{$black});
    --h2-line-height-mobile: var(--imported_h2Mobile_line_height, 24px);
    --h2-text-transform-mobile: var(--imported_h2Mobile_text_transform, capitalize);
    --h2-font-style-mobile: var(--imported_h2Mobile_font_style, normal);

    // h3 desktop
    --h3-font-size: var(--imported_h3_font_size, 18px);
    --h3-font-weight: var(--imported_h3_font_weight, 700);
    --h3-color: var(--imported_h3_color, #{$black});
    --h3-line-height: var(--imported_h3_line_height, 24px);
    --h3-text-transform: var(--imported_h3_text_transform, capitalize);
    --h3-font-style: var(--imported_h3_font_style, normal);

    // h3 mobile
    --h3-font-size-mobile: var(--imported_h3Mobile_font_size, 16px);
    --h3-font-weight-mobile: var(--imported_h3Mobile_font_weight, 700);
    --h3-color-mobile: var(--imported_h3Mobile_color, #{$black});
    --h3-line-height-mobile: var(--imported_h3Mobile_line_height, 24px);
    --h3-text-transform-mobile: var(--imported_h3Mobile_text_transform, capitalize);
    --h3-font-style-mobile: var(--imported_h3Mobile_font_style, normal);

    // paragraph
    --paragraph-font-size: var(--imported_paragraph_font_size, 14px);
    --paragraph-font-weight: var(--imported_paragraph_font_weight, 400);
    --paragraph-color: var(--imported_paragraph_color, #{$black});
    --paragraph-line-height: var(--imported_paragraph_line_height, 20px);
    --paragraph-text-transform: var(--imported_paragraph_text_transform, none);
    --paragraph-font-style: var(--imported_paragraph_font_style, normal);

    // paragraph mobile
    --paragraph-font-size-mobile: var(--imported_paragraphMobile_font_size, 14px);
    --paragraph-font-weight-mobile: var(--imported_paragraphMobile_font_weight, 400);
    --paragraph-color-mobile: var(--imported_paragraphMobile_color, #{$black});
    --paragraph-line-height-mobile: var(--imported_paragraphMobile_line_height, 16px);
    --paragraph-text-transform-mobile: var(--imported_paragraphMobile_text_transform, none);
    --paragraph-font-style-mobile: var(--imported_paragraphMobile_font_style, normal);

    // caption
    --caption-font-size: var(--imported_caption_font_size, 12px);
    --caption-font-weight: var(--imported_caption_font_weight, 400);
    --caption-color: var(--imported_caption_color, #{$black});
    --caption-line-height: var(--imported_caption_line_height, 20px);
    --caption-text-transform: var(--imported_caption_text_transform, none);
    --caption-font-style: var(--imported_caption_font_style, normal);

    // caption mobile
    --caption-font-size-mobile: var(--imported_captionMobile_font_size, 12px);
    --caption-font-weight-mobile: var(--imported_captionMobile_font_weight, 400);
    --caption-color-mobile: var(--imported_captionMobile_color, #{$black});
    --caption-line-height-mobile: var(--imported_captionMobile_line_height, 16px);
    --caption-text-transform-mobile: var(--imported_captionMobile_text_transform, none);
    --caption-font-style-mobile: var(--imported_captionMobile_font_style, normal);
}

* {
    position: relative;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    border: 0;
    vertical-align: baseline;
    font-family: inherit;
    outline: 0;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, .004);
    // backface-visibility: hidden;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-tap-highlight-color: transparent;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
label,
summary {
    display: block;
}

header,
footer,
main {
    font-size: 12px;

    @include mobile {
        font-size: 14px;
    }
}

main {
    --border-width: 1px;

    min-height: calc(100vh - var(--header-height) - var(--breadcrumbs-height));
    max-width: 100vw;

    @include mobile {
        min-height: calc(99vh - var(--header-total-height) - var(--navigation-tabs-height) + 2 * var(--border-width));
        margin-block-start: var(--header-total-height);
        margin-block-end: var(--footer-total-height);
    }

    @include desktop {
        min-height: calc(100vh - 470px);
    }

    &.MenuPage {
        @include mobile {
            min-height: calc(100vh - 70px - var(--navigation-tabs-height));
            background: var(--body-background-color);
        }
    }

    &.Checkout {
        min-height: calc(100vh - 111px);

        &_isDetailsStep {
            @include mobile {
                min-height: calc(100vh - 115px);
            }
        }
    }
}

table {
    border-collapse: collapse;
    border-spacing: 0;
    table-layout: fixed;
    margin-block-end: 14px;

    @include mobile {
        margin-block-end: 20px;
    }
}

img {
    width: 100%;
}

html {
    font-size: $font-standard-size;
}

body {
    font-family: $base-font-family;
    font-weight: $font-weight-regular;
    color: var(--primary-light-color);
    scroll-behavior: smooth;
    background-color: var(--body-background-color);

    @include print-only {
        background-color: transparent;
        margin-top: 0 !important;
    }
}

a {
    color: var(--secondary-dark-color);
    text-decoration: inherit;
    transition-timing-function: ease-out;
    transition-duration: .25s;

    &:hover {
        cursor: pointer;
    }
}

h1 {
    color: var(--h1-color);
    font-size: var(--h1-font-size);
    font-style: var(--h1-font-style);
    font-weight: var(--h1-font-weight);
    line-height: var(--h1-line-height);
    text-transform: var(--h1-text-transform);

    @include mobile {
        color: var(--h1-color-mobile);
        font-size: var(--h1-font-size-mobile);
        font-style: var(--h1-font-style-mobile);
        font-weight: var(--h1-font-weight-mobile);
        line-height: var(--h1-line-height-mobile);
        text-transform: var(--h1-text-transform-mobile);
    }

    + h2,
    + h3,
    + h4 {
        margin-block-start: 7px;

        @include mobile {
            margin-block-start: 8px;
        }
    }
}

h4,
p,
li,
dl {
    color: var(--paragraph-color);
    font-size: var(--paragraph-font-size);
    font-style: var(--paragraph-font-style);
    font-weight: var(--paragraph-font-weight);
    line-height: var(--paragraph-line-height);
    text-transform: var(--paragraph-text-transform);

    @include mobile {
        color: var(--paragraph-color-mobile);
        font-size: var(--paragraph-font-size-mobile);
        font-style: var(--paragraph-font-style-mobile);
        font-weight: var(--paragraph-font-weight-mobile);
        line-height: var(--paragraph-line-height-mobile);
        text-transform: var(--paragraph-text-transform-mobile);
    }
}

li {
    list-style-type: none;
    list-style-position: inside;
    margin-block-end: 0;

    // override default blue color for lists in Safari since iOS15

    & * {
        color: var(--primary-light-color);
    }

    ol & {
        counter-increment: list;

        @include mobile {
            counter-increment: list;
        }
    }

    &:last-child {
        margin-block-end: 20px;
    }
}

p {
    margin-block-end: 15px;

    &.caption {
        color: var(--caption-color);
        font-size: var(--caption-font-size);
        font-style: var(--caption-font-style);
        font-weight: var(--caption-font-weight);
        line-height: var(--caption-line-height);
        text-transform: var(--caption-text-transform);

        @include mobile {
            color: var(--caption-color-mobile);
            font-size: var(--caption-font-size-mobile);
            font-style: var(--caption-font-style-mobile);
            font-weight: var(--caption-font-weight-mobile);
            line-height: var(--caption-line-height-mobile);
            text-transform: var(--caption-text-transform-mobile);
        }
    }
}

input,
select {
    min-height: 40px;
    min-width: 56px;
}

input,
select,
textarea {
    font-size: 14px;
}

textarea {
    resize: none;
}

mark {
    margin-right: 5px;
}

iframe {
    max-width: 100%;
}

a,
input,
button,
textarea,
select,
details {
    @include mobile {
        touch-action: manipulation;
    }
}

details {
    > summary {
        list-style: none;

        &::-webkit-details-marker {
            display: none;
        }
    }
}

.showStickyHeader {
    .Header {
        &-TopMenu {
            @include desktop {
                opacity: 0;
                visibility: hidden;
                transform: translateY(-100%);
            }
        }

        &-Nav {
            @include desktop {
                transform: translateY(0);
            }
        }

        &-Wrapper {
            &_isHome {
                @include mobile {
                    --header-total-height: 120px;

                    .Header-TopMenu {
                        opacity: 0;
                        visibility: hidden;
                        transform: translateY(-100%);
                    }

                    .Header-Nav {
                        --header-nav-height: 120px;

                        padding-top: 0;
                        transform: translateY(0%);
                    }
                }
            }
        }
    }

    .Menu {
        &-MenuWrapper {
            @include desktop {
                transform: translateY(0);
            }
        }
    }

    .Overlay {
        &:not(.Overlay_isStatic) {
            &.Overlay_isHome {
                @include mobile {
                    top: 120px;
                }
            }
        }
    }

    .NotificationList {
        @include desktop {
            top: 148px;
        }
    }
}
