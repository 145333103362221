/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/mixins';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.CarouselScrollItem {
    display: flex;
    scroll-snap-align: start;
    border: solid 1px var(--color-gray4);
    transition: border-color var(--animation-speed), filter var(--animation-speed);
    flex: 1;
    cursor: pointer;
    width: 80px;
    height: 80px;
    margin-right: 10px;

    &:first-child {
        margin-left: 33px;
    }

    &_isActive {
        border-color: var(--primary-base-color);
    }

    &:focus,
    &:hover {
        border-color: var(--primary-base-color);
    }
}
