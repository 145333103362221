/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/mixins';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.ContactPage {
    &-Heading {
        margin-block-start: 24px;
        margin-block-end: 18px;
        font-size: 24px;
    }

    &-ColumnWrapper {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        padding-bottom: calc(var(--header-nav-height) - 5px);

        @include mobile {
            display: block;
            padding-top: 20px;
            margin-bottom: 0;
        }

        @include desktop {
            max-width: 820px;
            margin: auto;
            padding-top: 40px;
            justify-content: center;
        }
    }

    &-Column {
        &_isContent {
            display: none;

            @include mobile {
                margin-top: 50px;
            }

            h4 {
                margin-bottom: 15px;
            }

            p {
                color: var(--primary-light-color);
                margin-bottom: 10px;

                &:last-of-type {
                    margin-bottom: 0;
                }

                a {
                    --link-color: var(--primary-light-color);

                    font-weight: $font-weight-regular;
                }
            }
        }
    }

    &-Form {
        border: 1px solid var(--color-grey16);
        padding: 25px 20px;
        display: flex;
        flex-wrap: wrap;
        width: 100%;

        @include desktop {
            padding-left: 30px;
            padding-right: 30px;
            max-width: 380px;
        }

        &Heading {
            text-align: center;
            margin-bottom: 5px;
            width: 100%;

            @include mobile {
                font-size: 24px;
                line-height: 30px;
            }
        }
    }

    .ContactForm {
        width: 100%;
    }

    &-Cms-Top {
        margin-bottom: 30px;
    }

    .Form {
        .Button {
            @include mobile {
                width: 100%;
            }
        }
    }
}
