/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/mixins';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

:root {
    --default-notification-height: 37px;
    --notification-info-color: var(--imported_notificationInfo_color, var(--primary-light-color));
    --notification-info-background: var(--imported_notificationInfo_background_color, var(--primary-info-color));
    --notification-info-height: var(--imported_notificationInfo_height, var(--default-notification-height));
    --notification-error-color: var(--imported_notificationError_color, var(--secondary-error-color));
    --notification-error-background: var(--imported_notificationError_background_color, var(--primary-error-color));
    --notification-error-height: var(--imported_notificationError_height, var(--default-notification-height));
    --notification-success-color: var(--imported_notificationSuccess_color, var(--primary-light-color));
    --notification-success-background: var(--imported_notificationSuccess_background_color, var(--primary-success-color));
    --notification-success-height: var(--imported_notificationSuccess_height, var(--default-notification-height));
}

.Notification {
    --animation-duration: 0; /* to sync CSS animation and JS callback */

    will-change: max-height, opacity, transform, padding, margin;
    align-items: center;
    background: var(--notification-background);
    color: var(--notification-color);
    display: inline-flex;
    min-height: var(--notification-height);
    margin-block-end: 0;
    max-width: 100vw;
    overflow: hidden;
    padding: 10px 45px 8px 15px;
    position: relative;
    top: 0;

    @include mobile {
        width: 100%;
    }

    @include desktop {
        margin-block-end: 20px;
        max-width: 345px;
        min-width: 345px;
    }

    p {
        color: var(--notification-color);
    }

    &-Text {
        font-size: 14px;
        line-height: 20px;
        letter-spacing: $letter-spacing1;
        margin-block-start: 0;
        margin-inline-end: 40px;
        margin-block-end: 0;
    }

    &-Debug {
        white-space: pre-wrap;
        background: var(--color-white);
        color: var(--color-black);
        padding: 5px;
        max-height: 150px;
        overflow: scroll;
    }

    &-Button {
        cursor: pointer;
        font-size: 0;
        appearance: none;
        background: none;
        position: absolute;
        right: 10px;
        top: 8px;
        width: 20px;
        height: 20px;
        opacity: 1;

        @include close-icon;

        &:focus {
            outline: none;
        }

        &::before {
            color: var(--notification-color);
            font-size: 15px;
            line-height: 15px;
        }
    }

    &_is {
        &_opening {
            animation: fadeInStart var(--animation-duration) cubic-bezier(.645, .045, .355, 1) forwards;
        }

        &_closing {
            animation: fadeOutStart var(--animation-duration) cubic-bezier(.645, .045, .355, 1) forwards;
        }
    }

    &_type {
        &_error {
            --notification-color: var(--notification-error-color);
            --notification-background: var(--notification-error-background);
            --notification-height: var(--notification-error-height);
        }

        &_success {
            --notification-color: var(--notification-success-color);
            --notification-background: var(--notification-success-background);
            --notification-height: var(--notification-success-height);
        }

        &_info {
            --notification-color: var(--notification-info-color);
            --notification-background: var(--notification-info-background);
            --notification-height: var(--notification-info-height);
        }
    }

    @keyframes fadeInStart {
        from {
            opacity: 0;
            transform: translateY(-300px);
        }

        to {
            opacity: 1;
            transform: translateY(0);
        }
    }

    @keyframes fadeOutStart {
        from {
            opacity: 1;
            transform: translateY(0);
            max-height: 100px;
            margin-block-end: 5px;

            @include mobile {
                margin-block-end: 20px;
            }
        }

        to {
            opacity: 0;
            transform: translateY(-300px);
            max-height: 0;
            padding: 0;
            margin: 0;
        }
    }
}
