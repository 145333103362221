/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../../src/style/abstract/variables';
@import '../../../../../src/style/abstract/mixins';
@import '../../../../../src/style/abstract/media';
@import '../../../../../src/style/abstract/button';
@import '../../../../../src/style/abstract/loader';
@import '../../../../../src/style/abstract/icons';
@import '../../../../../src/style/abstract/parts';

/**
 * KemanaPWA - Progressive Web App for Magento
 * Copyright © Kemana, Inc. All rights reserved.
 *
 * @package kemana-store-locator
 * @author ashangunawardena
 */
 
.PlacesAutocomplete {
    &-Container {
        position: relative;
        width: 100%;

        .Field {
            margin-top: 0;
        }
    }

    &-Input {
        width: 100%;

        &:focus {
            --input-border-color-focus: var(--input-border-color);
        }
    }
}

.pac-container {
    border: 1px solid var(--input-border-color);
    border-radius: 0;
    border-top: 0;
    box-shadow: none;
    z-index: 1;
    margin-top: -1px;
    padding-top: 10px;

    @include mobile {
        left: 0 !important;
        right: 0;
        width: calc(100% - 20px) !important;
        margin-left: 10px;
        margin-right: -10px;
    }

    @include desktop {
        width: 485px !important;
    }

    &::before {
        content: '';
        background-color: var(--input-border-color);
        height: 1px;
        position: absolute;
        left: 1.5rem;
        right: 1.5rem;
        top: 0;
        bottom: 0;
    }

    .pac-item {
        @include h6;

        font-weight: $font-weight-regular;
        letter-spacing: $letter-spacing9;
        padding: 8px 14px 7px;
        border-top: 0;

        @include mobile {
            margin-bottom: 0;
        }

        &:hover {
            background-color: transparent;

            @include desktop {
                cursor: pointer;

                &:before {
                    content: '';
                    background-color: var(--select-option-hover-background);
                    position: absolute;
                    left: 0;
                    right: 0;
                    z-index: -1;
                    top: 0;
                    bottom: 0;
                }
            }
        }

        .pac-icon {
            margin-top: 0;
            background-image: none;
            margin-right: 10px;
            width: auto;
            height: auto;

            @include location-icon;
        }

        .pac-item-query {
            color: var(--primary-light-color);
            font-size: 15px;
            font-weight: $font-weight-semibold;
        }
    }
}
