/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../../src/style/abstract/variables';
@import '../../../../../src/style/abstract/mixins';
@import '../../../../../src/style/abstract/media';
@import '../../../../../src/style/abstract/button';
@import '../../../../../src/style/abstract/loader';
@import '../../../../../src/style/abstract/icons';
@import '../../../../../src/style/abstract/parts';

/**
 * KemanaPWA - Progressive Web App for Magento
 * Copyright © Kemana, Inc. All rights reserved.
 *
 * @package kemana-blog
 * @author ashangunawardena
 */

.BlogPage {
    padding-bottom: 90px;
    margin-top: 80px;

    @include desktop {
        padding-bottom: calc(var(--header-nav-height) - 5px);
        margin-top: 0;
    }

    &-Details {
        display: grid;

        @include desktop {
            justify-content: space-between;
            grid-template-columns: 2.8fr 1fr;
            grid-gap: 50px;
        }

        .Socialshare-Wrap {
            display: flex;
            align-items: center;
            border-top: 1px solid var(--color-grey16);
            padding-top: 30px;
            margin-top: 30px;

            small {
                font-weight: $font-weight-regular;
                color: var(--color-gray2);
                font-size: 12px;
                line-height: 15px;
                letter-spacing: $letter-spacing;
                margin-right: 20px;
            }

            .SocialShare {
                padding: 0;

                &-ShareBtn {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 30px;
                    height: 30px;
                    border-radius: 30px;
                    text-align: center;
                    background: var(--primary-base-color);

                    &:hover {
                        @include desktop {
                            background: var(--primary-light-color);
                        }
                    }
                }

                &-ShareBtnWrapperFacebook {
                    a {
                        @include footer-facebook-icon;
                        margin-right: 10px;
                    }
                }

                &-ShareBtnWrapperTwitter {
                    a {
                        @include footer-twitter-icon;
                    }
                }

                &-ShareBtnWrapperEmail,
                &-ShareBtnWrapperWhatsapp {
                    display: none;
                }
            }
        }
    }

    .Blog {
        &-Wrapper {
            &-PostContent {
                padding: 30px 0;
                border-bottom: 1px solid var(--color-grey16);
                margin-bottom: 30px;

                @include paragraph-text;
            }
        }

        &-BlogImage {
            height: 190px;
            object-fit: cover;
            margin-bottom: 30px;

            @include desktop {
                height: 515px;
            }
        }

        &-DetailsTitle {
            @include mobile {
                display: none;
            }
        }
    }

    .BlogsPage {
        &-Sidebar {
            @include mobile {
                margin-top: 50px;
            }

            &Inner {
                border: 1px solid var(--color-grey16);
                padding: 25px 20px 20px;
            }
    
            h4 {
                margin-bottom: 20px;
            }
        }
    }
}
