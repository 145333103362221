/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/mixins';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

.NoMatch {
    opacity: 0;
    animation: appear 100ms 300ms forwards;

    &-Button {
        margin-top: 15px;

        @include desktop {
            margin-top: 35px;
        }
    }

    &-Wrapper {
        text-align: center;

        @include desktop {
            padding-bottom: 92px;
        }

        h2 {
            margin-bottom: 20px;
        }

        img {
            width: 200px;
            margin-bottom: 30px;
            margin-top: 60px;

            @include desktop {
                margin-top: 13px;
                margin-bottom: 40px;
            }
        }
    }
}
